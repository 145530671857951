import React from 'react'

import UserTable from '../Components/UserTable';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    backgroundPage: {
        backgroundColor: "#F2F2F2",
        paddingBottom: "20px",
    }
})

function UserPage() {
    const classes = useStyles();
    return (
        <div className={classes.backgroundPage}>
            {/* <NavBar /> */}
            {/* <h2>{t('UserTab.title')}</h2> */}
            <UserTable />
        </div>
    )
}

export default UserPage