import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NavBar from "../Components/NavBar";
import imgModif from "../static/images/modifier.png";
import imgSupp from "../static/images/supprimer.png";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import TablePagination from "@material-ui/core/TablePagination";
import MaskedInput from "react-text-mask";
import axios from "../plugins/axios";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
import imgResetFilters from "../static/images/Composant5–1.png";
// import imgbtnOpen from '../static/images/open.png'
import imgbtnSettings from "../static/images/settings.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";
import MenuItem from "@material-ui/core/MenuItem";
// import { Select } from '@material-ui/core';
import { Alert } from "@material-ui/lab";

import cookie from "js-cookie";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    display: "inline-flex",
    width: "100%",
  },
  rootwNavOpen: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  // navOpen: {
  //   width: "260px"
  // },
  // navClose: {
  //   width:"70px"
  // },
  table: {
    // minWidth: 1300,
    overflow: "auto",
    width: "100%",
  },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "100%",
  },
  autoComp: {
    padding: "0px",
  },
  textFieldMasked: {
    width: "100%",
    marginBottom: "3.3px",
    height: "2.6em",
    borderRadius: "5px",
    // border: '0',
    // padding: '18.5px 14px',
    display: "block",
  },
  labelPop: {
    marginBottom: "8px",
  },
  selectBox: {
    width: "100%",
    marginTop: "9.5px",
  },
  divForm: {
    width: "100%",
    margin: "10px",
    // margin: theme.spacing(3)
  },
  columnEdit: {
    width: "46%",
    padding: "10px",
    display: "inline-flex",
  },
  dialogDetail: {
    width: "550px",
  },
  tableCell: {
    textAlign: "center",
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  btnAdd: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  formGrp: {
    width: "100%",
  },
  rightSideEntete: {
    width: "35%",
    // minWidth: "600px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  btnsReinit: {
    marginTop: "15px",
    textAlign: "end",
  },
  volumeTotalDiv: {
    width: "319px",
    height: "108px",
    margin: "40px",
  },
  labelMac: {
    marginBottom: "8px",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnOpenNavDroite: {
    //   margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
  filtrageRow: {
    backgroundColor: "#F2F2F2",
  },
  txtfield: {
    width: "100%",
    backgroundColor: "#FFF",
    height: "40px",
  },
  txtfieldD: {
    width: "100%",
    backgroundColor: "#FFF",
    marginTop: "0px",
    marginBottom: "0px",
  },
  txtfieldDate: {
    // position: "absolute",
    // height:"5px",
    zIndex: "1",
    // marginTop: "-8px",
    // marginLeft: "5px"
    // color: "#3f51b5"
  },
  tableCellDate: {
    padding: "0px",
  },
  btnOneReinit: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
  btnTwoReinit: {
    backgroundColor: "#25C2F2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
});

function DeviceTable(props) {
  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://192.168.1.13:3004".
    axios_customUrl = "https://auth.piink-teknology.com";
  }

  const [terminaux, setTerminaux] = useState([]);
  const { t, i18n } = useTranslation();
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [stateForm, setStateForm] = React.useState({
    _id: "",
    mac: "",
    model: "",
    datetime: "",
    partName: "",
    clientName: "",
    siteAssoc: "",
    deviceName: "",
    addOrUpdate: false,
    titleAddOrUpdate: t("Buttons.add"),
  });
  const [stateFormFilter, setStateFormFilter] = React.useState({
    nomPart: "",
    mac: "",
    model: "",
    datetime: "",
    datetime2: "",
    clientName: "",
    siteAssoc: "",
    deviceName: "",
  });
  const [termNchanged, setTermNchanged] = React.useState(false);
  const [rowToSupp, setRowToSupp] = React.useState("");
  const [stateFilter, setStateFilter] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [filterOn, setFilterOnOff] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    entriesLength: 0,
  });
  const classes = useStyles();

  const [rightBarState, setRightBarState] = React.useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);
  const [checked, setChecked] = React.useState(true);

  const [site, setSite] = React.useState([]);

  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
    parts: [],
  });

  const [entreprise, setEntreprise] = React.useState({
    listEnt: [],
    entSelected: "",
  });
  const [listTermName, setListTermName] = React.useState([]);

  const [firstCall, setFirstCall] = React.useState(true);

  const [isDataCharging, setIsDataCharging] = React.useState(false);

  //handle errors
  const [openMsgErrEmpty, setOpenMsgErrEmpty] = React.useState(false);
  const [openMsgErrTermName, setOpenMsgErrTermName] = React.useState(false);
  const [openMsgErrMacNotValid, setOpenMsgErrMacNotValid] =
    React.useState(false);
  const [openMsgMacAlreadyExist, setOpenMsgMacAlreadyExist] =
    React.useState(false);

  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");

  const maskMac = [
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
    ":",
    /[0-9a-fA-F]/,
    /[0-9a-fA-F]/,
  ];

  // const monthFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
  // const monthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  var loc = "";
  function logout() {
    // console.log(inf)
    // var wiioauthTok = cookie.get("wiioauth")
    // console.log(token)
    // console.log(wiioauthTok)
    cookie.remove("token");
    cookie.remove("infosUser");

    //test logout wiioauth redirect don't wwork
    loc = axios_customUrl + "/logout?client_id=piinkHub&token=" + token;

    window.location = loc;
  }

  useEffect(() => {
    if (
      (stateFormFilter.datetime !== "" && stateFormFilter.datetime2 !== "") ||
      (stateFormFilter.datetime === "" &&
        stateFormFilter.datetime2 === "" &&
        (stateFormFilter.nomPart ||
          stateFormFilter.mac ||
          stateFormFilter.model ||
          stateFormFilter.clientName ||
          stateFormFilter.siteAssoc ||
          stateFormFilter.deviceName))
    ) {
      //si date et date2 alors test que date < date2
      // console.log(stateFormFilter.date)
      // console.log(new Date(stateFormFilter.date))
      // console.log("call filter")
      callFilter();
    } else {
      // console.log("call Simple")
      callSimple();
    }
  }, [stateFormFilter]);

  useEffect(() => {
    async function fetchData() {
      var response = {};
      setIsDataCharging(true);
      var usersInfo = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );

      if (
        !usersInfo.data.sitePartenaire.includes(
          usersInfo.data.entrepriseUser._id
        )
      ) {
        usersInfo.data.sitePartenaire.push(usersInfo.data.entrepriseUser._id);
      }

      // console.log(usersInfo.data)
      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
        parts: usersInfo.data.sitePartenaire,
      });

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }

      // var responseSite = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/site')
      // setSite({ listSite: responseSite.data })

      if (window.location.search.includes("filterTerm")) {
        var filterbase = window.location.search.split("=");

        while (filterbase[1].includes("%20")) {
          filterbase[1] = filterbase[1].replace("%20", " ");
        }
        var filterbasefinal = filterbase[1];
        console.log(filterbasefinal);
        setStateFormFilter({ siteAssoc: filterbasefinal });
      } else {
        var response3 = {};
        if (infsplit[1] === "adminPiink") {
          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/device",
            {
              params: {
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
              },
            }
          );

          // console.log(response.data)

          // console.log("modif idSite Array => ")
          // console.log(response.data)

          response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/device/count"
          );
          setPagination({ entriesLength: response3.data });

          var responseEnt = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/company"
          );
          setEntreprise({ listEnt: responseEnt.data });
          console.log(responseEnt.data);

          var responseSite = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/site"
          );
          setSite(responseSite.data);
        } else if (infsplit[1] === "partenaire") {
          console.log("start partenaire");

          if (
            usersInfo.data.sitePartenaire !== undefined &&
            usersInfo.data.sitePartenaire !== null
          ) {
            //get terms
            response = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL +
                "/device/getDevicesFromPart",
              {
                params: {
                  nbOfPage: page,
                  nbMeasureDisplay: rowsPerPage + 1,
                  listPartenaire: usersInfo.data.sitePartenaire,
                },
              }
            );

            //get count
            response3 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/device/count",
              {
                params: {
                  listPartenaire: usersInfo.data.sitePartenaire,
                },
              }
            );
            setPagination({ entriesLength: response3.data });

            //get ent
            var responseEnt = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL +
                "/company/getCompaniesForPartenaire",
              {
                params: {
                  listPartenaire: usersInfo.data.sitePartenaire,
                },
              }
            );
            setEntreprise({ listEnt: responseEnt.data });

            //get sites
            var responseSite = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
              {
                params: {
                  listPartenaire: usersInfo.data.sitePartenaire,
                },
              }
            );
            setSite(responseSite.data);
          }

          console.log("end device");
        } else {
          var listSiteUser = usersInfo.data.siteUser;
          console.log(listSiteUser);

          var listID = [];
          listSiteUser.forEach((elt) => {
            listID.push(elt._id);
          });

          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/device/getDeviceForUser",
            {
              params: {
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
                siteUser: listID,
              },
            }
          );

          response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/device/count",
            {
              params: {
                siteUser: listID,
              },
            }
          );
          setPagination({ entriesLength: response3.data });

          setEntreprise({ listEnt: [usersInfo.data.entrepriseUser] });

          setSite(usersInfo.data.siteUser);
        }

        var terminaux = response.data;
        // console.log("fetchData")
        console.log(terminaux);
        setTerminaux(terminaux);
      }

      setFirstCall(false);
      setIsDataCharging(false);
    }

    fetchData();
  }, []);

  const callBackNav = (navData) => {
    setIsNavBarOpen(navData);
    // console.log(navData)
  };

  const handleChangeCollapse = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeFilter = (name) => (event) => {
    setStateFormFilter({ ...stateFormFilter, [name]: event.target.value });
    // console.log(stateFormFilter)
    //wait change then -> callFilter()
  };

  const handleChangeTermName = (value) => {
    console.log("modif deviceName");
    setTermNchanged(true);
    setStateForm({ ...stateForm, deviceName: value });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  const handleChangeStateFilter = (event) => {
    setStateFilter(event.target.value);
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }
    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }
  //definition du swicth
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#eb4034",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleChangeEnt = (value) => {
    // console.log(value)
    stateForm.siteAssoc = [];
    setStateForm({ ...stateForm, clientName: value });

    updateSiteChoice(value);

    // handleChangeSite("")
    // console.log(value)
    // if(value === "" || value === null){

    //     console.log("vide ou null")
    //     setStateForm({ ...stateForm, clientName: "" })
    // }
  };

  const handleChange = (name) => (event) => {
    // console.log("change ")
    setStateForm({ ...stateForm, [name]: event.target.value });
    if (name === "clientName") {
      updateSiteChoice(event.target.value);
    }
  };

  const handleChangeSite = (value) => {
    // console.log(value)
    setStateForm({ ...stateForm, siteAssoc: value });

    if (value === "" || value === null) {
      // console.log("vide ou null")
      setStateForm({ ...stateForm, deviceName: "" });
    }

    updateListOfTermNames(value);
  };

  async function updateListOfTermNames(value) {
    // console.log(value)
    var listOfTermNames = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/device/getTermNames",
      {
        params: {
          site: value,
        },
      }
    );
    // console.log("listOfTermNames.data ===>")
    // console.log(listOfTermNames.data)
    setListTermName(listOfTermNames.data);
  }

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
    setTermNchanged(false);
  };

  const handleClickOpenSupp = (row) => {
    setRowToSupp(row);
    setOpenSupp(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleCloseSupp = () => {
    setRowToSupp("");
    setOpenSupp(false);
  };

  function renderOPtionSite() {
    return site.map((sit, i) => {
      return (
        <MenuItem
          id={sit._id}
          label="sit"
          value={sit.nomSite}
          key={i}
          name={sit.nomSite}
        >
          {sit.nomSite}
        </MenuItem>
      );
    });
  }

  async function updateSiteChoice(entU) {
    // console.log("update choice")
    // console.log(stateForm.entU)
    console.log(entU);
    //get _id from nom ent
    // var idOfEnt = ""
    // entreprise.listEnt.forEach(elt => {
    //     if(elt.nomEntreprise === entU){
    //         idOfEnt= elt._id
    //     }
    // });

    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/site/getSiteFromEnt",
      {
        params: {
          entId: entU,
        },
      }
    );
    console.log(response.data);
    setSite(response.data);
  }

  async function handleChangePage(event, newPage) {
    setIsDataCharging(true);
    // console.log(newPage)
    setPage(newPage);

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);
      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = newPage;
      paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

      var response = {};

      if (infsplit[1] === "user") {
        var arrayIdSite = [];
        identityUser.sites.forEach((elt) => {
          arrayIdSite.push(elt._id);
        });
        paramsFilter.siteUser = arrayIdSite;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/device/deviceFilter",
        {
          params: paramsFilter,
        }
      );

      var devices = response.data;
      setTerminaux(devices);
    } else {
      var response = {};
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device",
          {
            params: {
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        //get terms
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/getDevicesFromPart",
          {
            params: {
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var arrayIdSite = [];
        identityUser.sites.forEach((elt) => {
          arrayIdSite.push(elt._id);
        });
        var listSiteUser = arrayIdSite;
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/getDeviceForUser",
          {
            params: {
              siteUser: listSiteUser,
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
      }

      var devices = response.data;
      setTerminaux(devices);
    }

    // // call measure table with updated params
    // var response = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/device', {
    //     params: {
    //         nbOfPage: newPage,
    //         nbDisplay: rowsPerPage + 1
    //     }

    // })
    setIsDataCharging(false);
  }

  async function handleChangeRowsPerPage(event, newRowPerPage) {
    setIsDataCharging(true);
    // console.log(newRowPerPage.props.value)
    setRowsPerPage(newRowPerPage.props.value);
    setPage(0);

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = 0;
      paramsFilter.nbMeasureDisplay = newRowPerPage.props.value + 1;

      var response = {};
      if (infsplit[1] === "user") {
        var arrayIdSite = [];
        identityUser.sites.forEach((elt) => {
          arrayIdSite.push(elt._id);
        });
        paramsFilter.siteUser = arrayIdSite;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      // console.log(paramsFilter)

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/device/deviceFilter",
        {
          params: paramsFilter,
        }
      );

      var devices = response.data;
      setTerminaux(devices);
    } else {
      var response = {};
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/getDevicesFromPart",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var listSiteUser = identityUser.sites;
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/getDeviceForUser",
          {
            params: {
              siteUser: listSiteUser,
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      }
      var devices = response.data;
      setTerminaux(devices);
    }

    // var response = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/device', {
    //     params: {
    //         nbOfPage: 0,
    //         nbDisplay: newRowPerPage.props.value + 1
    //     }

    // })

    // var devices = response.data;
    // setTerminaux(devices)
    setIsDataCharging(false);
  }

  async function clickAdd(row) {
    // console.log("ok")
    console.log(row);
    //show popup détail
    handleClickOpenAdd();
    //remplir les champs avec les infos de row
    if (row === undefined) {
      // console.log("add")
      stateForm._id = "";
      stateForm.mac = "";
      stateForm.model = "";
      // stateForm.datetime = moment().format('YYYY-MM-DDThh:mm')
      stateForm.partName = "";
      stateForm.clientName = "";
      stateForm.siteAssoc = "";
      stateForm.deviceName = "";
      stateForm.addOrUpdate = false;
      stateForm.titleAddOrUpdate = t("Buttons.add");
    } else {
      //  console.log("update")
      // console.log(row.date.substring(0,16))
      stateForm._id = row._id;
      stateForm.mac = row.macTerm;
      stateForm.model = row.modelTerm;
      // stateForm.datetime = row.updatedAt.substring(0,16)
      stateForm.partName = row.partenaireName;
      if (row.siteDevice != null) {
        stateForm.clientName = row.siteDevice.entrepriseSite.nomEntreprise;
        // stateForm.siteAssoc = row.siteDevice.nomSite
        if (
          row.siteDevice.nomSite !== undefined &&
          row.siteDevice.nomSite !== ""
        ) {
          var response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/site/getSiteFromEnt",
            {
              params: {
                entId: row.siteDevice.entrepriseSite.nomEntreprise,
              },
            }
          );
          console.log("row.siteDevice._id");
          console.log(row.siteDevice._id);
          console.log(response.data);
          var tabIndex = "";
          for (var i = 0; i < response.data.length; i++) {
            console.log(row.siteDevice._id === response.data[i]._id);
            if (row.siteDevice._id === response.data[i]._id) {
              // console.log(i)
              // console.log(site[i])
              tabIndex = response.data[i];
            }
          }
          console.log(tabIndex);
          setSite(response.data);
          stateForm.siteAssoc = tabIndex;
        }
      }

      updateListOfTermNames(row.siteDevice.nomSite);
      stateForm.deviceName = row.deviceName;
      stateForm.addOrUpdate = true;
      stateForm.titleAddOrUpdate = t("Buttons.update");
    }
    // console.log(stateForm.datetime)
    // console.log(stateForm.mac)
  }

  function macAddIsValid(mac) {
    var arrayOfValid = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      ":",
    ];
    for (var i = 0; i < mac.length; i++) {
      if (!arrayOfValid.includes(mac.charAt(i))) {
        return false;
      }
    }
    return true;
  }

  async function handleValidate() {
    // console.log(stateForm)
    // console.log("stateForm.mac")
    if (stateForm.mac !== "" && stateForm.mac !== undefined) {
      var macAddValid = macAddIsValid(stateForm.mac);
      if (macAddValid) {
        console.log("mac is valid !");
        var termExist = false;
        if (stateForm.deviceName !== "" && stateForm.deviceName !== undefined) {
          //check if name already exist
          console.log(stateForm.siteAssoc);
          termExist = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/device/termNameExist",
            {
              params: {
                site: stateForm.siteAssoc,
                termToAdd: stateForm.deviceName,
              },
            }
          );
        }

        if (!termExist.data || !termNchanged) {
          var sitesSelected = "";
          console.log("stateForm.siteAssoc =>");
          console.log(stateForm.siteAssoc);
          // var tab1 = stateForm.siteAssoc.nomSite
          // var tab2 = site
          // console.log(tab2)
          // for (var i = 0; i < tab2.length; i++) {
          //     console.log(tab2[i].nomSite +" === "+tab1)
          //     if (tab1 === tab2[i].nomSite) {
          //         console.log("in ...")
          //         sitesSelected = tab2[i]._id
          //     }
          // }

          // console.log(sitesSelected)

          //test if mac already exist
          var macExist = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/device/macExist",
            {
              params: {
                macTerm: stateForm.mac,
              },
            }
          );

          console.log(stateForm);
          if (macExist.data) {
            if (stateForm.titleAddOrUpdate === t("Buttons.update")) {
              await axios.defaultAxios.put(
                axios.defaultAxios.defaults.baseURL + "/device",
                {
                  _id: stateForm._id,
                  partenaireName: stateForm.partName,
                  macTerm: stateForm.mac,
                  modelTerm: stateForm.model,
                  clientName: stateForm.clientName,
                  // dateActivation: stateForm.datetime,
                  siteDevice: stateForm.siteAssoc._id,
                  deviceName: stateForm.deviceName,
                }
              );
            } else {
              //erreur
              // console.log("mac adresse exist déja !")
              setOpenMsgMacAlreadyExist(true);
              return;
            }
          } else {
            await axios.defaultAxios.post(
              axios.defaultAxios.defaults.baseURL + "/device",
              {
                partenaireName: stateForm.partName,
                macTerm: stateForm.mac,
                modelTerm: stateForm.model,
                clientName: stateForm.clientName,
                // dateActivation: stateForm.datetime,
                siteDevice: stateForm.siteAssoc._id,
                deviceName: stateForm.deviceName,
              }
            );
          }

          setTermNchanged(false);
          var response2 = [];
          var response3 = [];

          if (infsplit[1] === "adminPiink") {
            response2 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/device",
              {
                params: {
                  nbOfPage: page,
                  nbMeasureDisplay: rowsPerPage + 1,
                },
              }
            );

            response3 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/device/count"
            );
          } else if (infsplit[1] === "partenaire") {
            response2 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL +
                "/device/getDevicesFromPart",
              {
                params: {
                  nbOfPage: page,
                  nbMeasureDisplay: rowsPerPage + 1,
                  listPartenaire: identityUser.parts,
                },
              }
            );

            response3 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/device/count",
              {
                params: {
                  listPartenaire: identityUser.parts,
                },
              }
            );
          } else {
            var listSiteUser = identityUser.sites;
            response2 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/device/getDeviceForUser",
              {
                params: {
                  siteUser: listSiteUser,
                  nbOfPage: page,
                  nbMeasureDisplay: rowsPerPage + 1,
                },
              }
            );

            var arrayIdSite = [];
            identityUser.sites.forEach((elt) => {
              arrayIdSite.push(elt._id);
            });

            response3 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/device/count",
              {
                params: {
                  siteUser: arrayIdSite,
                },
              }
            );
          }

          setTerminaux(response2.data);
          setPagination({ entriesLength: response3.data });
          setFilterOnOff(false);

          handleCloseAdd();
        } else {
          if (termExist.data) {
            setOpenMsgErrTermName(true);
          }
        }
      } else {
        setOpenMsgErrMacNotValid(true);
      }
    } else {
      setOpenMsgErrEmpty(true);
    }
  }

  async function clickSupp() {
    console.log(rowToSupp._id);
    await axios.defaultAxios.delete(
      axios.defaultAxios.defaults.baseURL + "/device",
      {
        data: {
          macAddress: rowToSupp.macTerm,
        },
      }
    );

    var response2 = [];
    if (infsplit[1] === "adminPiink") {
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/device",
        {
          params: {
            nbOfPage: page,
            nbMeasureDisplay: rowsPerPage + 1,
          },
        }
      );
    } else {
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/device",
        {
          params: {
            nbOfPage: page,
            nbMeasureDisplay: rowsPerPage + 1,
            listPartenaire: identityUser.parts,
          },
        }
      );
    }

    setTerminaux(response2.data);

    handleCloseSupp();
  }

  // function setDateString() {
  //     var dateToday = new Date()
  //     var day = dateToday.getDate()
  //     var month = dateToday.getUTCMonth()
  //     var year = dateToday.getFullYear()

  //     var dateOfTodayVar = ""
  //     if (i18n.language === "fr") {
  //         month = monthFr[month]
  //     } else if (i18n.language === "en") {
  //         month = monthEn[month]
  //     }
  //     dateOfTodayVar = day + " " + month + " " + year
  //     // console.log(dateOfTodayVar)
  //     return (dateOfTodayVar)

  // }

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  // async function callRefresh(){
  //     setStateFormFilter({
  //         nomPart: "",
  //         mac: "",
  //         model: "",
  //         datetime: "",
  //         datetime2: "",
  //         clientName: "",
  //         siteAssoc: ""
  //     })
  //     setStateFilter("")
  //     setPage(0)
  //     setFilterOnOff(false)

  //     // callSimple()
  // }

  async function ResetFilters() {
    setStateFormFilter({
      nomPart: "",
      mac: "",
      model: "",
      datetime: "",
      datetime2: "",
      clientName: "",
      siteAssoc: "",
      deviceName: "",
    });
    // callSimple()
  }

  async function callFilter() {
    setIsDataCharging(true);
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);
    setPage(0);
    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = 0;
    paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

    if (infsplit[1] === "user") {
      var arrayIdSite = [];
      identityUser.sites.forEach((elt) => {
        arrayIdSite.push(elt._id);
      });
      // console.log(identityUser.sites)
      paramsFilter.siteUser = arrayIdSite;
    } else if (infsplit[1] === "partenaire") {
      console.log("call partenaire");
      paramsFilter.listPartenaire = identityUser.parts;
    }

    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/device/deviceFilter",
      {
        params: paramsFilter,
      }
    );

    setFilterOnOff(true);
    setTerminaux(response.data);

    var response3 = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/device/countFilter",
      {
        params: paramsFilter,
      }
    );
    setPagination({ entriesLength: response3.data });
    setIsDataCharging(false);
  }

  function pressEnter(ev) {
    if (ev.key === "Enter") {
      callFilter();
      ev.preventDefault();
    }
  }

  async function callSimple() {
    setIsDataCharging(true);
    // console.log("callSimple")
    if (firstCall === false) {
      setStateFilter("");
      setPage(0);
      setFilterOnOff(false);

      var response3 = [];
      if (infsplit[1] === "adminPiink") {
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/count"
        );
      } else if (infsplit[1] === "partenaire") {
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/count",
          {
            params: {
              sitePartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var arrayIdSite = [];
        identityUser.sites.forEach((elt) => {
          arrayIdSite.push(elt._id);
        });

        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/count",
          {
            params: {
              siteUser: arrayIdSite,
            },
          }
        );
      }

      setPagination({ entriesLength: response3.data });

      var response2 = [];
      // console.log(infsplit[1] )
      if (infsplit[1] === "adminPiink") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/getDevicesFromPart",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var arrayIdSite = [];
        identityUser.sites.forEach((elt) => {
          arrayIdSite.push(elt._id);
        });
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/device/getDeviceForUser",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
              siteUser: arrayIdSite,
            },
          }
        );
      }

      console.log("call Simple");
      console.log(response2.data);
      setTerminaux(response2.data);
    }
    setIsDataCharging(false);
  }

  // async function getSiteName(siteId){

  //     if(siteId !== undefined || siteId !== ""){
  //         // console.log("call req")
  //         var result = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/site/getSiteNameFromId', {
  //             params: {
  //                 _id: siteId
  //             }
  //         })

  //         return result.data[0].nomSite;
  //     }

  //     return ""
  // }

  function filterConditions(paramsFilter) {
    if (
      stateFormFilter.nomPart !== "" &&
      stateFormFilter.nomPart !== undefined
    ) {
      paramsFilter.nomPart = stateFormFilter.nomPart; //{ $regex: /789$/ }
    }

    if (stateFormFilter.mac !== "" && stateFormFilter.mac !== undefined) {
      paramsFilter.mac = stateFormFilter.mac; //{ $regex: /789$/ }
    }

    if (stateFormFilter.model !== "" && stateFormFilter.model !== undefined) {
      paramsFilter.model = stateFormFilter.model; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.datetime !== "" &&
      stateFormFilter.datetime !== undefined
    ) {
      paramsFilter.datetime = stateFormFilter.datetime; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.datetime2 !== "" &&
      stateFormFilter.datetime2 !== undefined
    ) {
      paramsFilter.datetime2 = stateFormFilter.datetime2; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.clientName !== "" &&
      stateFormFilter.clientName !== undefined
    ) {
      paramsFilter.clientName = stateFormFilter.clientName; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.siteAssoc !== "" &&
      stateFormFilter.siteAssoc !== undefined
    ) {
      paramsFilter.siteAssoc = stateFormFilter.siteAssoc; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.deviceName !== "" &&
      stateFormFilter.deviceName !== undefined
    ) {
      paramsFilter.deviceName = stateFormFilter.deviceName; //{ $regex: /789$/ }
    }

    return paramsFilter;
  }

  return (
    <div className={classes.body}>
      {/* <Button onClick={() => changeLanguage('fr')}>fr</Button>
            <Button onClick={() => changeLanguage('en')}>en</Button>
            <div>
                <Button onClick={() => clickAdd()} >{t('Buttons.add')}</Button>
            </div> */}
      <NavBar
        pageCallBack={callBackNav}
        dataFromParent={"terminalPage"}
        dataIsUser={identityUser.role}
      />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <div className={classes.entete}>
          <div className={classes.volumeTotalDiv}>
            <h1>{t("DeviceTab.title")}</h1>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                {identityUser.ent}
              </span>
            </div>
            <Divider />
            <div className={classes.btnsReinit}>
              <Button
                className={classes.btnOneReinit}
                onClick={() => clickAdd()}
                className={classes.btnAdd}
              >
                {t("Buttons.add")}
              </Button>
              {/* <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('Buttons.resetFilter')}</Button> */}
              {/* <Button className={classes.btnOneReinit} onClick={() => resetVolChecked()}>{t('ScanTable.Button.resetVol')}</Button>
                            <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('ScanTable.Button.resetFilter')}</Button> */}
            </div>
          </div>
          {/* <button onClick={() => changeLanguage('fr')}>fr</button>
                    <button onClick={() => changeLanguage('en')}>en</button> */}
          {/* <Button onClick={() => clickUpdate()} className={classes.btnAdd}>{t('UserTab.Button.add')}</Button> */}
        </div>
        <Paper className={classes.tableau}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {t("DeviceTab.TableCell.deviceName")}
                </TableCell>
                {/* <TableCell className={classes.tableCell}>{t('DeviceTab.TableCell.partName')}</TableCell> */}
                <TableCell className={classes.tableCell}>
                  {t("DeviceTab.TableCell.macAddr")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("DeviceTab.TableCell.Model")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("DeviceTab.TableCell.clientName")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("DeviceTab.TableCell.dateActiv")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("DeviceTab.TableCell.siteAssoc")}
                </TableCell>
              </TableRow>
              <TableRow className={classes.filtrageRow}>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="deviceName"
                      variant="outlined"
                      value={stateFormFilter.deviceName}
                      onChange={handleChangeFilter("deviceName")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                {/* <TableCell>
                                    <Collapse in={checked}>
                                        <TextField
                                            className={classes.txtfield}
                                            margin="dense"
                                            id="partName"
                                            variant="outlined"
                                            value={stateFormFilter.nomPart}
                                            onChange={handleChangeFilter('nomPart')}
                                            onKeyPress={(ev) => pressEnter(ev)}
                                        />
                                    </Collapse>
                                </TableCell> */}
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="mac"
                      variant="outlined"
                      value={stateFormFilter.mac}
                      onChange={handleChangeFilter("mac")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="model"
                      variant="outlined"
                      value={stateFormFilter.model}
                      onChange={handleChangeFilter("model")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="clientName"
                      variant="outlined"
                      value={stateFormFilter.clientName}
                      onChange={handleChangeFilter("clientName")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell className={classes.tableCellDate}>
                  <Collapse in={checked}>
                    <div>
                      <label className={classes.txtfieldDate}>
                        {t("DeviceTab.dateD")}
                      </label>
                      <TextField
                        className={classes.txtfieldD}
                        margin="dense"
                        id="datetime"
                        type="date"
                        variant="outlined"
                        value={stateFormFilter.datetime}
                        onChange={handleChangeFilter("datetime")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    </div>
                    <div>
                      <label className={classes.txtfieldDate}>
                        {t("DeviceTab.dateF")}
                      </label>
                      <TextField
                        className={classes.txtfieldD}
                        margin="dense"
                        id="datetime2"
                        type="date"
                        variant="outlined"
                        value={stateFormFilter.datetime2}
                        onChange={handleChangeFilter("datetime2")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    </div>
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="siteAssoc"
                      variant="outlined"
                      value={stateFormFilter.siteAssoc}
                      onChange={handleChangeFilter("siteAssoc")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button onClick={() => ResetFilters()}>
                    <img src={imgResetFilters} alt="detail" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataCharging ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                // <div>
                //   <CircularProgress />
                // </div>
                terminaux.map((row) => (
                  <TableRow hover key={row._id}>
                    <TableCell className={classes.tableCell}>
                      {row.deviceName}
                    </TableCell>
                    {/* <TableCell component="th" scope="row" className={classes.tableCell}>
                                                {row.partenaireName}
                                            </TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {row.macTerm}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.modelTerm}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.siteDevice != null
                        ? row.siteDevice.entrepriseSite.nomEntreprise
                        : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {new Intl.DateTimeFormat(i18n.language, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(row.createdAt))}
                      {/* {row.dateActivation} */}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.siteDevice ? row.siteDevice.nomSite : ""}
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Button type="button" onClick={(e) => clickAdd(row)}>
                        <img src={imgModif} alt="imgmodif" />
                      </Button>
                    </TableCell>

                    {identityUser.role === "user" ? (
                      <TableCell></TableCell>
                    ) : (
                      <TableCell align="right" className={classes.tableCell}>
                        <Button
                          type="button"
                          onClick={(e) => handleClickOpenSupp(row)}
                        >
                          <img src={imgSupp} alt="imgsupp" />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={pagination.entriesLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="terminaux par page :"
          />
        </Paper>

        {rightBarState ? (
          <Drawer
            className={classes.barreLatDroiteOpen}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper2,
            }}
            anchor="right"
          >
            {/* logout */}
            <Button
              className={classes.btnCloseNavDroite}
              onClick={(e) => toggleRightBarPanel(true)}
            >
              {" "}
              <img src={imgbtnClose} alt="img close" />{" "}
            </Button>
            v1.0.10
            <h2 className={classes.userNameBarLatOpen}>
              {identityUser.name} {identityUser.firstname}
            </h2>
            <span className={classes.typeUserBarLatOpen}>
              {identityUser.role}
            </span>
            <span className={classes.EntrepriseBarLatOpen}>
              {identityUser.ent}
            </span>
            <Divider className={classes.separatorRightBar} />
            <div className={classes.langDiv}>
              <IconButton onClick={() => changeLanguage("fr")}>
                <img src={drapeauFr} alt="drapeau francais" />
              </IconButton>
              <IconButton onClick={() => changeLanguage("en")}>
                <img src={drapeauEn} alt="drapeau anglais" />
              </IconButton>
              {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
                        <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
            </div>
            <Divider className={classes.separatorRightBar} />
            <span className={classes.labelRightBar}>filtres de recherche</span>
            <FormControlLabel
              className={classes.switchFilter}
              control={
                <IOSSwitch
                  // className={classes.switchFilter}
                  checked={checked}
                  onChange={handleChangeCollapse}
                />
              }
              label={checked ? "On" : "Off"}
            />
            <Button
              variant="contained"
              className={classes.btnLogout}
              endIcon={<img src={logoutIcon} />}
              onClick={() => logout()}
            >
              Logout
            </Button>
            {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
          </Drawer>
        ) : (
          <Drawer
            className={classes.barreLatDroiteClose}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <Button
              className={classes.btnOpenNavDroite}
              onClick={(e) => toggleRightBarPanel(false)}
            >
              {" "}
              <img
                src={imgbtnSettings}
                alt="setting"
                className={classes.imgbtnset}
              />{" "}
            </Button>
            <span className={classes.userSideTxt}></span>
            {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
          </Drawer>
        )}

        <Dialog
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {stateForm.titleAddOrUpdate}
          </DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <DialogContentText></DialogContentText>
            <FormGroup aria-label="position" className={classes.columnEdit}>
              <div className={classes.divForm}>
                <InputLabel
                  htmlFor="formatted-text-mask-input"
                  className={classes.labelMac}
                >
                  {t("DeviceTab.mac") + "*"}
                </InputLabel>
                <MaskedInput
                  margin="dense"
                  id="mac"
                  mask={maskMac}
                  placeholderChar={"\u2000"}
                  // label={t('DeviceTab.mac')}
                  className={classes.textFieldMasked}
                  variant="outlined"
                  defaultValue={stateForm.mac}
                  onChange={handleChange("mac")}
                  disabled={stateForm.addOrUpdate}
                />
              </div>
              <div className={classes.divForm}>
                <InputLabel htmlFor="formatted-text-mask-input">
                  {t("DeviceTab.model")}
                </InputLabel>
                <TextField
                  margin="dense"
                  id="model"
                  // label={t('DeviceTab.model')}
                  className={classes.textField}
                  variant="outlined"
                  defaultValue={stateForm.model}
                  onChange={handleChange("model")}
                />
              </div>
              {/* <div className={classes.divForm}>
                                <InputLabel htmlFor="formatted-text-mask-input" className={classes.labelPop}>{t('DeviceTab.dateActiv') + "*"}</InputLabel>
                                <TextField
                                    margin="dense"
                                    id="datetime"
                                    type="datetime-local"
                                    // label={t('DeviceTab.date')}
                                    className={classes.textField}
                                    variant="outlined"
                                    defaultValue={stateForm.datetime}
                                    onChange={handleChange('datetime')}
                                />
                            </div> */}
              <div className={classes.divForm}>
                <InputLabel htmlFor="formatted-text-mask-input">
                  {t("DeviceTab.TableCell.deviceName")}
                </InputLabel>
                {/* <TextField
                                    margin="dense"
                                    id="deviceName"
                                    // label={t('DeviceTab.model')}
                                    className={classes.textField}
                                    variant="outlined"
                                    defaultValue={stateForm.deviceName}
                                    onChange={handleChange('deviceName')}
                                /> */}
                <Autocomplete
                  value={stateForm.deviceName}
                  freeSolo
                  autoSelect
                  onChange={(event, newValue) => {
                    console.log(newValue);

                    if (newValue && newValue.inputValue) {
                      console.log(newValue.inputValue);
                      handleChangeTermName(newValue.inputValue);
                      return;
                    }
                    handleChangeTermName(newValue);
                  }}
                  id="deviceName"
                  options={listTermName.map((option) => option.deviceName)}
                  getOptionDisabled={(option) => true}
                  disabled={
                    stateForm.clientName === "" || stateForm.siteAssoc === null
                  }
                  // getOptionLabel={option => option.nomEntreprise}
                  // onChange={handleChange('entrepriseSite')}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // className={classes.autoComp}
                      // label={t('DeviceTab.clientName')}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </FormGroup>
            <FormGroup aria-label="position" className={classes.columnEdit}>
              <div className={classes.divForm}>
                <InputLabel
                  htmlFor="formatted-text-mask-input"
                  className={classes.labelPop}
                >
                  {t("DeviceTab.clientName")}
                </InputLabel>
                {/* <TextField
                                    margin="dense"
                                    id="clientName"
                                    // label={t('DeviceTab.model')}
                                    className={classes.textField}
                                    variant="outlined"
                                    defaultValue={stateForm.clientName}
                                    onChange={handleChange('clientName')}
                                /> */}
                <Autocomplete
                  value={stateForm.clientName}
                  onChange={(event, newValue) => {
                    // console.log(newValue)
                    // console.log(newValue.inputValue)
                    if (newValue && newValue.inputValue) {
                      handleChangeEnt(newValue.inputValue);
                      return;
                    }
                    handleChangeEnt(newValue);
                  }}
                  id="clientName"
                  options={entreprise.listEnt.map(
                    (option) => option.nomEntreprise
                  )}
                  // disabled={identityUser.role === "user"}
                  // getOptionLabel={option => option.nomEntreprise}
                  // onChange={handleChange('entrepriseSite')}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // className={classes.autoComp}
                      // label={t('DeviceTab.clientName')}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className={classes.divForm}>
                <InputLabel
                  htmlFor="formatted-text-mask-input"
                  className={classes.labelPop}
                >
                  {t("DeviceTab.siteAssoc")}
                </InputLabel>
                <Autocomplete
                  value={stateForm.siteAssoc}
                  disabled={
                    stateForm.clientName === "" || stateForm.clientName === null
                  }
                  onChange={(event, newValue) => {
                    // console.log(newValue)
                    // console.log(newValue.inputValue)
                    if (newValue && newValue.inputValue) {
                      handleChangeSite(newValue.inputValue);
                      return;
                    }
                    handleChangeSite(newValue);
                  }}
                  id="siteAssoc"
                  options={site}
                  getOptionLabel={(option) => option.nomSite}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // className={classes.selectBoxAuto}
                      // label={t('DeviceTab.siteAssoc')}
                      variant="outlined"
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      {/* <Checkbox
                                                // icon={icon}
                                                // checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                // checked={selected}
                                            /> */}
                      {option.nomSite}
                    </React.Fragment>
                  )}
                />
                {/* <InputLabel htmlFor="formatted-text-mask-input">{t('DeviceTab.siteAssoc')}</InputLabel>
                                <TextField
                                    margin="dense"
                                    id="siteAssoc"
                                    // label={t('DeviceTab.model')}
                                    className={classes.textField}
                                    variant="outlined"
                                    defaultValue={stateForm.siteAssoc}
                                    onChange={handleChange('siteAssoc')}
                                /> */}
              </div>
              {/* <div className={classes.divForm}>
                                <InputLabel htmlFor="formatted-text-mask-input">{t('DeviceTab.partName')}</InputLabel>
                                <TextField
                                    margin="dense"
                                    id="partName"
                                    // label={t('DeviceTab.model')}
                                    className={classes.textField}
                                    variant="outlined"
                                    defaultValue={stateForm.partName}
                                    onChange={handleChange('partName')}
                                />
                            </div> */}
            </FormGroup>
            <Collapse in={openMsgErrEmpty}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrEmpty(false);
                }}
              >
                {t("error.errorMsgEmpty")}
              </Alert>
            </Collapse>
            <Collapse in={openMsgErrTermName}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrTermName(false);
                }}
              >
                {t("error.errorMsgTermName")}
              </Alert>
            </Collapse>
            <Collapse in={openMsgErrMacNotValid}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrMacNotValid(false);
                }}
              >
                {t("error.errorMsgMacNotValid")}
              </Alert>
            </Collapse>
            <Collapse in={openMsgMacAlreadyExist}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgMacAlreadyExist(false);
                }}
              >
                {t("error.errorMsgMacAlreadyExist")}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAdd} color="primary">
              {t("DeviceTab.annuler")}
            </Button>
            <Button onClick={handleValidate} color="primary">
              {t("DeviceTab.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSupp}
          onClose={handleCloseSupp}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{t("Buttons.suppr")}</DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <DialogContentText>{t("DeviceTab.confirmSupp")}</DialogContentText>
            <DialogActions>
              <Button onClick={handleCloseSupp} color="primary">
                {t("Buttons.non")}
              </Button>
              <Button onClick={clickSupp} color="primary">
                {t("Buttons.oui")}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

// DeviceTable.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default DeviceTable;
