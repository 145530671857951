import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import YouTube from 'react-youtube';

const useStyles = makeStyles({
    video: {
        // margin: "10px",
        width: "100%",
        height: "400px"
    }
})


function YtComponent(props){

    var videoId = props.videoId
    const classes = useStyles();

    return (
        <YouTube
            videoId={videoId}
            className={classes.video}
        />
    )
}

export default YtComponent;