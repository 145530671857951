import React from 'react'

import EntrepriseTable from '../Components/EntrepriseTable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    backgroundPage: {
        backgroundColor: "#F2F2F2",
        paddingBottom: "20px",
    }
})

function EntreprisePage() {
    const classes = useStyles();
    return (
        <div className={classes.backgroundPage}>
            {/* <h2>{t('EntrepriseTab.title')}</h2> */}
            <EntrepriseTable />
        </div>
    )
}

export default EntreprisePage