import React from 'react'

import SiteTable from '../Components/SiteTable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    base: {
        marginLeft: "270px"
    },
    backgroundPage: {
        backgroundColor: "#F2F2F2",
        paddingBottom: "20px",
    }
})

function SitePage() {
    const classes = useStyles();
    
    return (
        <div className={classes.backgroundPage}>
            {/* <h2>{t('SiteTab.title')}</h2> */}
            <SiteTable />
        </div>
    )
}

export default SitePage