import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Dashboard from '../Pages/Dashboard';
import TerminalPage from '../Pages/TerminalPage';
import UserPage from '../Pages/UserPage';
import SitePage from '../Pages/SitePage';
import EntreprisePage from '../Pages/EntreprisePage';
import SavPage from '../Pages/SavPage'
import PrivateRoute from './PrivateRoute';

function AppRouters() {
  
  return (
      
    <Router>
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/terminaux" component={TerminalPage} />
      <PrivateRoute path="/users" component={UserPage} />
      <PrivateRoute path="/sites" component={SitePage} />
      <PrivateRoute path="/entreprises" component={EntreprisePage} />
      <PrivateRoute path="/sav" component={SavPage} />
    </Router>
      
    
  )
}

// function About() {
//   return <h2>About</h2>;
// }

export default AppRouters
