import React from 'react'
import TerminalTable from '../Components/TerminalTable';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    backgroundPage: {
        backgroundColor: "#F2F2F2",
        paddingBottom: "20px",
    }
})

function TerminalPage() {
    const classes = useStyles();
    return (
        <div  className={classes.backgroundPage}>
            {/* <NavBar />
            <h2>{t('TerminalTab.title')}</h2> */}
            <TerminalTable />
        </div>
    )
}

export default TerminalPage