import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NavBar from "../Components/NavBar";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import imgModif from "../static/images/modifier.png";
import imgSupp from "../static/images/supprimer.png";
import imgResetFilters from "../static/images/Composant5–1.png";

import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
// import InputLabel from '@material-ui/core/InputLabel';
// import { Select, MenuItem } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
// import imgbtnOpen from '../static/images/open.png'
import imgbtnSettings from "../static/images/settings.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";
import cookie from "js-cookie";

import iconTerminal from "../static/images/smartphone001 - E108.png";
import iconMesure from "../static/images/cube.png";

import { useTranslation } from "react-i18next";

import axios from "../plugins/axios";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    display: "inline-flex",
    width: "100%",
  },
  rootwNavOpen: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  // navOpen: {
  //   width: "260px"
  // },
  // navClose: {
  //   width:"70px"
  // },
  table: {
    // minWidth: 1300,
    overflow: "auto",
    width: "100%",
  },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "90%",
    margin: "10px",
  },
  divForm: {
    width: "100%",
    marginBottom: "10px",
    // margin: theme.spacing(3)
  },
  dialogDetail: {
    width: "550px",
  },
  columnEdit: {
    width: "50%",
  },
  tableCell: {
    textAlign: "center",
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  btnAdd: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  formGrp: {
    width: "100%",
  },
  rightSideEntete: {
    width: "35%",
    minWidth: "600px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  btnsReinit: {
    marginTop: "15px",
    textAlign: "end",
  },
  volumeTotalDiv: {
    width: "319px",
    height: "108px",
    margin: "40px",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnOpenNavDroite: {
    //   margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
  filtrageRow: {
    backgroundColor: "#F2F2F2",
  },
  txtfield: {
    backgroundColor: "#FFF",
    height: "40px",
    width: "100%",
  },
  txtfieldDate: {
    position: "absolute",
    zIndex: "1",
    marginTop: "-8px",
    marginLeft: "5px",
    // color: "#3f51b5"
  },
  btnOneReinit: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
  btnTwoReinit: {
    backgroundColor: "#25C2F2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
  iconClass: {
    marginLeft: "10px",
  },
});

function SiteTable() {
  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://3:3004"
    axios_customUrl = "https://auth.piink-teknology.com";
  }

  const [sites, setSites] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [stateForm, setStateForm] = React.useState({
    _id: "",
    id: "",
    nomSite: "",
    adresseSite: "",
    paysSite: "",
    villeSite: "",
    codePostalSite: "",
    entrepriseSite: "",
    locked: true,
    titleAddOrUpdate: t("Buttons.add"),
  });
  const [isToggled, setToggled] = useState(false);
  const [stateFormFilter, setStateFormFilter] = React.useState({
    nomSite: "",
    adresseSite: "",
    paysSite: "",
    villeSite: "",
    codePostalSite: "",
    entrepriseSite: "",
  });
  const [stateFilter, setStateFilter] = React.useState("");
  const [filterOn, setFilterOnOff] = React.useState(false);
  const [rowToSupp, setRowToSupp] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    entriesLength: 0,
  });
  const [entreprise, setEntreprise] = React.useState({
    listEnt: [],
    entSelected: "",
  });
  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
    parts: [],
  });

  const classes = useStyles();

  const [rightBarState, setRightBarState] = React.useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);
  const [checked, setChecked] = React.useState(true);
  const [firstCall, setFirstCall] = React.useState(true);
  const [isDataCharging, setIsDataCharging] = React.useState(false);
  // const monthFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
  // const monthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");

  //handle errors
  const [openMsgErrEmpty, setOpenMsgErrEmpty] = React.useState(false);

  var loc = "";
  function logout() {
    // console.log(inf)
    // var wiioauthTok = cookie.get("wiioauth")
    // console.log(token)
    // console.log(wiioauthTok)
    cookie.remove("token");
    cookie.remove("infosUser");

    //test logout wiioauth redirect don't wwork
    loc = axios_customUrl + "/logout?client_id=piinkHub&token=" + token;

    window.location = loc;
  }

  useEffect(() => {
    if (
      stateFormFilter.nomSite ||
      stateFormFilter.adresseSite ||
      stateFormFilter.paysSite ||
      stateFormFilter.villeSite ||
      stateFormFilter.codePostalSite ||
      stateFormFilter.entrepriseSite
    ) {
      //si date et date2 alors test que date < date2
      // console.log(stateFormFilter.date)
      // console.log(new Date(stateFormFilter.date))
      // console.log("call filter")
      callFilter();
    } else {
      // console.log("call simple")
      callSimple();
    }
  }, [stateFormFilter]);

  useEffect(() => {
    async function fetchData() {
      setIsDataCharging(true);
      var response = {};
      var usersInfo = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );

      if (
        !usersInfo.data.sitePartenaire.includes(
          usersInfo.data.entrepriseUser._id
        )
      ) {
        usersInfo.data.sitePartenaire.push(usersInfo.data.entrepriseUser._id);
      }

      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
        parts: usersInfo.data.sitePartenaire,
      });

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }

      // console.log("window.location.search")
      // console.log(window.location.search)

      if (window.location.search.includes("filterSite")) {
        // console.time('time')
        // console.log("not vide")
        // console.log("window.location.search not vide ->")
        // console.log(window.location.search)
        var filterbase = window.location.search.split("=");
        // var filterbasefinal = ""
        while (filterbase[1].includes("%20")) {
          filterbase[1] = filterbase[1].replace("%20", " ");
        }
        var filterbasefinal = filterbase[1];
        console.log(filterbasefinal);
        setStateFormFilter({ entrepriseSite: filterbasefinal });
        // console.timeEnd('time')
      } else {
        var response3 = {};
        var responseEnt = {};
        // console.log("call basique")
        if (infsplit[1] === "adminPiink") {
          // console.log("admin")
          //init list entreprise
          responseEnt = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/company"
          );
          setEntreprise({ listEnt: responseEnt.data });
          // console.log(responseEnt.data)

          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/site",
            {
              params: {
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
              },
            }
          );
          // console.log(response.data)

          response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/site/count"
          );
          setPagination({ entriesLength: response3.data });
        } else if (infsplit[1] === "partenaire") {
          if (
            usersInfo.data.sitePartenaire !== undefined &&
            usersInfo.data.sitePartenaire !== null
          ) {
            //get site
            response = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
              {
                params: {
                  nbOfPage: page,
                  nbMeasureDisplay: rowsPerPage + 1,
                  listPartenaire: usersInfo.data.sitePartenaire,
                },
              }
            );

            //get siteCount
            var response3 = await axios.defaultAxios.get(
              axios.defaultAxios.defaults.baseURL + "/site/count",
              {
                params: {
                  listPartenaire: usersInfo.data.sitePartenaire,
                },
              }
            );
            setPagination({ entriesLength: response3.data });
          }
        } else {
          var listSiteUser = usersInfo.data.siteUser;
          console.log(listSiteUser);

          var listID = [];
          listSiteUser.forEach((elt) => {
            listID.push(elt._id);
          });

          // console.log("user")
          // console.log(listSiteUser)
          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/site/getSitesFromNameSite",
            {
              params: {
                nbOfPage: page,
                nbDisplay: rowsPerPage + 1,
                siteUser: listID,
              },
            }
          );
          setPagination({ entriesLength: listID.length });

          responseEnt = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/company/getCompanyById",
            {
              params: {
                _id: usersInfo.data.entrepriseUser._id,
              },
            }
          );
          var listEntUser = [];
          listEntUser.push(responseEnt.data);
          setEntreprise({ listEnt: listEntUser });
          // console.log(responseEnt)
        }

        var sites = response.data;
        // console.log(sites)
        setSites(sites);
      }

      setFirstCall(false);

      setIsDataCharging(false);
    }

    fetchData();
  }, []);

  const callBackNav = (navData) => {
    setIsNavBarOpen(navData);
    // console.log(navData)
  };

  const handleChangeCollapse = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeFilter = (name) => (event) => {
    setStateFormFilter({ ...stateFormFilter, [name]: event.target.value });
    // console.log(stateFormFilter)
    //wait change then -> callFilter()
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }
    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }
  //definition du swicth
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#eb4034",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleChange = (name) => (event) => {
    console.log(event.target.value);
    setStateForm({ ...stateForm, [name]: event.target.value });
  };

  const handleChangeEnt = (value) => {
    console.log(value);
    setStateForm({ ...stateForm, entrepriseSite: value });
  };

  const handleChangeLocked = () => setToggled(!isToggled);

  async function handleChangePage(event, newPage) {
    setIsDataCharging(true);
    // console.log(newPage)
    setPage(newPage);

    var response = {};

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);
      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = newPage;
      paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

      if (infsplit[1] === "user") {
        var listID = [];
        identityUser.sites.forEach((elt) => {
          listID.push(elt._id);
        });

        paramsFilter.siteUser = listID;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/siteFilter",
        {
          params: paramsFilter,
        }
      );
    } else {
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site",
          {
            params: {
              nbOfPage: newPage,
              nbDisplay: rowsPerPage + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
          {
            params: {
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var listSiteUser = identityUser.sites;
        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });
        // console.log("2")
        // console.log(listSiteUser)
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromNameSite",
          {
            params: {
              siteUser: listSiteUser,
              nbOfPage: newPage,
              nbDisplay: rowsPerPage + 1,
            },
          }
        );
      }
    }

    var sites = response.data;
    setSites(sites);
    setIsDataCharging(false);
  }

  async function handleChangeRowsPerPage(event, newRowPerPage) {
    setIsDataCharging(true);
    // console.log(newRowPerPage.props.value)
    setRowsPerPage(newRowPerPage.props.value);
    setPage(0);
    var response = {};

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = 0;
      paramsFilter.nbMeasureDisplay = newRowPerPage.props.value + 1;

      if (infsplit[1] === "user") {
        var listID = [];
        identityUser.sites.forEach((elt) => {
          listID.push(elt._id);
        });
        paramsFilter.siteUser = identityUser.sites;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/siteFilter",
        {
          params: paramsFilter,
        }
      );
    } else {
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site",
          {
            params: {
              nbOfPage: 0,
              nbDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
          {
            params: {
              nbOfPage: 0,
              nbDisplay: newRowPerPage.props.value + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var listSiteUser = identityUser.sites;
        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });
        // console.log("3")
        // console.log(listSiteUser)
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromNameSite",
          {
            params: {
              siteUser: listID,
              nbOfPage: 0,
              nbDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      }
    }

    var sites = response.data;
    setSites(sites);
    setIsDataCharging(false);
  }

  // function renderOPtionEnt() {
  //     return entreprise.listEnt.map((ent,i) => {
  //         return (
  //             <MenuItem
  //                 label="ent"
  //                 value={ent.nomEntreprise}
  //                 key={i}
  //                 name={ent.nomEntreprise}>{ent.nomEntreprise}</MenuItem>
  //         );
  //     });
  // }

  function clickAdd(row) {
    console.log(row);
    handleClickOpen();
    if (row === undefined) {
      stateForm._id = "";
      stateForm.id = "";
      stateForm.nomSite = "";
      stateForm.adresseSite = "";
      stateForm.paysSite = "";
      stateForm.villeSite = "";
      stateForm.codePostalSite = "";
      stateForm.entrepriseSite = "";
      setToggled(true);
      stateForm.titleAddOrUpdate = t("Buttons.add");
    } else {
      stateForm._id = row._id;
      stateForm.id = row.id;
      stateForm.nomSite = row.nomSite;
      stateForm.adresseSite = row.adresseSite;
      stateForm.villeSite = row.villeSite;
      stateForm.paysSite = row.paysSite;
      stateForm.codePostalSite = row.codePostalSite;
      stateForm.villeSite = row.villeSite;
      if (row.entrepriseSite != null) {
        stateForm.entrepriseSite = row.entrepriseSite.nomEntreprise;
      }
      setToggled(row.locked);
      stateForm.titleAddOrUpdate = t("Buttons.update");
    }
    // console.log(stateForm)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenSupp = (row) => {
    setRowToSupp(row);
    setOpenSupp(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSupp = () => {
    setRowToSupp("");
    setOpenSupp(false);
  };

  async function handleValidate() {
    console.log(stateForm);
    if (
      stateForm.nomSite !== "" &&
      stateForm.nomSite !== undefined &&
      stateForm.entrepriseSite !== "" &&
      stateForm.entrepriseSite !== undefined
    ) {
      var entId = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/company/getIdFromName",
        {
          params: {
            entName: stateForm.entrepriseSite,
          },
        }
      );

      if (stateForm._id === undefined || stateForm._id === "") {
        console.log("create new");
        stateForm.id = stateForm.nomSite + " - " + stateForm.entrepriseSite;
        await axios.defaultAxios.post(
          axios.defaultAxios.defaults.baseURL + "/site",
          {
            id: stateForm.id,
            nomSite: stateForm.nomSite,
            entrepriseSite: entId.data[0]._id,
            adresseSite: stateForm.adresseSite,
            paysSite: stateForm.paysSite,
            villeSite: stateForm.villeSite,
            codePostalSite: stateForm.codePostalSite,
            locked: isToggled,
          }
        );
      } else {
        await axios.defaultAxios.put(
          axios.defaultAxios.defaults.baseURL + "/site",
          {
            _id: stateForm._id,
            id: stateForm.id,
            nomSite: stateForm.nomSite,
            entrepriseSite: entId.data[0]._id,
            adresseSite: stateForm.adresseSite,
            paysSite: stateForm.paysSite,
            villeSite: stateForm.villeSite,
            codePostalSite: stateForm.codePostalSite,
            locked: isToggled,
          }
        );
      }

      var response = [];

      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/count"
        );
        setPagination({ entriesLength: response3.data });
      } else if (infsplit[1] === "partenaire") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
          {
            params: {
              nbOfPage: page,
              nbDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/count",
          {
            params: {
              listPartenaire: identityUser.parts,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      } else {
        var listSiteUser = identityUser.sites;
        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });
        // console.log("1")
        // console.log(listSiteUser)
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromNameSite",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
              siteUser: listID,
            },
          }
        );
        setPagination({ entriesLength: listSiteUser.length });
      }

      var sites = response.data;

      setSites(sites);
      handleClose();
    } else {
      setOpenMsgErrEmpty(true);
    }
  }

  async function clickSupp() {
    console.log(rowToSupp._id);
    await axios.defaultAxios.delete(
      axios.defaultAxios.defaults.baseURL + "/site",
      {
        data: {
          id: rowToSupp._id,
        },
      }
    );

    var response = [];

    if (infsplit[1] === "adminPiink") {
      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site",
        {
          params: {
            nbOfPage: page,
            nbMeasureDisplay: rowsPerPage + 1,
          },
        }
      );
      var response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/count"
      );
      setPagination({ entriesLength: response3.data });
    } else if (infsplit[1] === "partenaire") {
      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
        {
          params: {
            nbOfPage: page,
            nbDisplay: rowsPerPage + 1,
            listPartenaire: identityUser.parts,
          },
        }
      );

      var response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/count",
        {
          params: {
            listPartenaire: identityUser.parts,
          },
        }
      );
      setPagination({ entriesLength: response3.data });
    } else {
      var listSiteUser = identityUser.sites;
      var listID = [];
      listSiteUser.forEach((elt) => {
        listID.push(elt._id);
      });
      // console.log("1")
      // console.log(listSiteUser)
      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/getSitesFromNameSite",
        {
          params: {
            nbOfPage: page,
            nbMeasureDisplay: rowsPerPage + 1,
            siteUser: listID,
          },
        }
      );
      setPagination({ entriesLength: listSiteUser.length });
    }

    var sites = response.data;

    setSites(sites);

    handleCloseSupp();
  }

  // function setDateString() {
  //     var dateToday = new Date()
  //     var day = dateToday.getDate()
  //     var month = dateToday.getUTCMonth()
  //     var year = dateToday.getFullYear()

  //     var dateOfTodayVar = ""
  //     if (i18n.language === "fr") {
  //         month = monthFr[month]
  //     } else if (i18n.language === "en") {
  //         month = monthEn[month]
  //     }
  //     dateOfTodayVar = day + " " + month + " " + year
  //     // console.log(dateOfTodayVar)
  //     return (dateOfTodayVar)

  // }

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  // async function callRefresh(){
  //     setStateFormFilter({
  //         nomSite: "",
  //         adresseSite: "",
  //         paysSite: "",
  //         entrepriseSite: ""
  //     })
  //     // callSimple()
  // }

  async function ResetFilters() {
    setStateFormFilter({
      nomSite: "",
      adresseSite: "",
      paysSite: "",
      villeSite: "",
      codePostalSite: "",
      entrepriseSite: "",
    });
    setToggled(true);
    // callSimple()
  }

  async function callFilter() {
    setIsDataCharging(true);
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);
    setPage(0);
    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = 0;
    paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

    if (infsplit[1] === "user") {
      var listID = [];
      identityUser.sites.forEach((elt) => {
        listID.push(elt._id);
      });
      paramsFilter.siteUser = listID;
    } else if (infsplit[1] === "partenaire") {
      // console.log("partenaire filter added")
      paramsFilter.listPartenaire = identityUser.parts;
    }

    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/site/siteFilter",
      {
        params: paramsFilter,
      }
    );

    setFilterOnOff(true);
    setSites(response.data);

    var response3 = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/site/countFilter",
      {
        params: paramsFilter,
      }
    );
    setPagination({ entriesLength: response3.data });
    setIsDataCharging(false);
  }

  function pressEnter(ev) {
    if (ev.key === "Enter") {
      callFilter();
      ev.preventDefault();
    }
  }
  async function callSimple() {
    setIsDataCharging(true);
    // console.log(firstCall)
    if (firstCall === false) {
      setStateFilter("");
      setPage(0);
      setFilterOnOff(false);

      var response3 = [];

      var response2 = [];
      // console.log(window.location.search)
      // if (window.location.search !== "" || window.location.search !== undefined) {
      //     console.log("not empty windows location search")
      // } else {
      console.log("call Simple empty");
      if (infsplit[1] === "adminPiink") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/count"
        );
        setPagination({ entriesLength: response3.data });
      } else if (infsplit[1] === "partenaire") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromPart",
          {
            params: {
              nbOfPage: 0,
              nbDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/count",
          {
            params: {
              listPartenaire: identityUser.parts,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      } else {
        var listID = [];
        identityUser.sites.forEach((elt) => {
          listID.push(elt._id);
        });
        // console.log("4")
        // console.log(identityUser.sites)
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSitesFromNameSite",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
              siteUser: listID,
            },
          }
        );
        setPagination({ entriesLength: identityUser.sites.length });
      }

      setSites(response2.data);
      // }
    }
    setIsDataCharging(false);
  }

  function filterConditions(paramsFilter) {
    if (
      stateFormFilter.nomSite !== "" &&
      stateFormFilter.nomSite !== undefined
    ) {
      paramsFilter.nomSite = stateFormFilter.nomSite; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.adresseSite !== "" &&
      stateFormFilter.adresseSite !== undefined
    ) {
      paramsFilter.adresseSite = stateFormFilter.adresseSite; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.paysSite !== "" &&
      stateFormFilter.paysSite !== undefined
    ) {
      paramsFilter.paysSite = stateFormFilter.paysSite; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.villeSite !== "" &&
      stateFormFilter.villeSite !== undefined
    ) {
      paramsFilter.villeSite = stateFormFilter.villeSite; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.codePostalSite !== "" &&
      stateFormFilter.codePostalSite !== undefined
    ) {
      paramsFilter.codePostalSite = stateFormFilter.codePostalSite; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.entrepriseSite !== "" &&
      stateFormFilter.entrepriseSite !== undefined
    ) {
      paramsFilter.entrepriseSite = stateFormFilter.entrepriseSite; //{ $regex: /789$/ }
    }

    return paramsFilter;
  }

  async function selectRowTerm(event, rowInfo) {
    console.log("click site");

    var loc =
      "http://" +
      window.location.host +
      "/terminaux?filterTerm=" +
      rowInfo.nomSite;
    // console.log(loc)
    // console.log(window.location.search)
    window.location = loc;
  }

  return (
    <div className={classes.body}>
      {/* <button onClick={() => changeLanguage('fr')}>fr</button>
            <button onClick={() => changeLanguage('en')}>en</button>
            <div>
                <Button onClick={() => clickAdd()} >{t('Buttons.add')}</Button>
            </div> */}
      <NavBar
        pageCallBack={callBackNav}
        dataFromParent={"sitePage"}
        dataIsUser={identityUser.role}
      />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <div className={classes.entete}>
          <div className={classes.volumeTotalDiv}>
            <h1>{t("SiteTab.title")}</h1>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                {identityUser.ent}
              </span>
            </div>
            <Divider />
            <div className={classes.btnsReinit}>
              <Button
                className={classes.btnOneReinit}
                onClick={() => clickAdd()}
                className={classes.btnAdd}
              >
                {t("Buttons.add")}
              </Button>
              {/* <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('Buttons.resetFilter')}</Button> */}
              {/* <Button className={classes.btnOneReinit} onClick={() => resetVolChecked()}>{t('ScanTable.Button.resetVol')}</Button>
                            <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('ScanTable.Button.resetFilter')}</Button> */}
            </div>
          </div>
        </div>
        <Paper className={classes.tableau}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {/* <TableCell>{t('SiteTab.TableCell.id')}</TableCell> */}
                <TableCell className={classes.tableCell}>
                  {t("SiteTab.TableCell.nomS")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("SiteTab.TableCell.adrS")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("SiteTab.TableCell.paysS")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("SiteTab.TableCell.villeSite")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("SiteTab.TableCell.codePostalSite")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("SiteTab.TableCell.entS")}
                </TableCell>
              </TableRow>
              <TableRow className={classes.filtrageRow}>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="nomSite"
                      variant="outlined"
                      value={stateFormFilter.nomSite}
                      onChange={handleChangeFilter("nomSite")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="adresseSite"
                      variant="outlined"
                      value={stateFormFilter.adresseSite}
                      onChange={handleChangeFilter("adresseSite")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="paysSite"
                      variant="outlined"
                      value={stateFormFilter.paysSite}
                      onChange={handleChangeFilter("paysSite")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="villeSite"
                      variant="outlined"
                      value={stateFormFilter.villeSite}
                      onChange={handleChangeFilter("villeSite")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="codePostalSite"
                      variant="outlined"
                      value={stateFormFilter.codePostalSite}
                      onChange={handleChangeFilter("codePostalSite")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="entrepriseSite"
                      variant="outlined"
                      value={stateFormFilter.entrepriseSite}
                      onChange={handleChangeFilter("entrepriseSite")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button onClick={() => ResetFilters()}>
                    <img src={imgResetFilters} alt="detail" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataCharging ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                // <div>
                //   <CircularProgress />
                // </div>
                sites.map((row) => (
                  <TableRow key={row._id} className={classes.tableCell}>
                    {/* <TableCell component="th" scope="row">{row.id}</TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {row.nomSite}
                      {/* <img className={classes.iconClass} src={iconTerminal} onClick={event => selectRowTerm(event, row)}/> */}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.adresseSite}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.paysSite}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.villeSite}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.codePostalSite}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.entrepriseSite.nomEntreprise}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Button type="button" onClick={(e) => clickAdd(row)}>
                        <img src={imgModif} alt="modif" />
                      </Button>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Button
                        type="button"
                        onClick={(e) => handleClickOpenSupp(row)}
                      >
                        <img src={imgSupp} alt="suppr" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={pagination.entriesLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="terminaux par page :"
          />
        </Paper>

        {rightBarState ? (
          <Drawer
            className={classes.barreLatDroiteOpen}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper2,
            }}
            anchor="right"
          >
            {/* logout */}
            <Button
              className={classes.btnCloseNavDroite}
              onClick={(e) => toggleRightBarPanel(true)}
            >
              {" "}
              <img src={imgbtnClose} alt="btnCLose" />{" "}
            </Button>
            v1.0.10
            <h2 className={classes.userNameBarLatOpen}>
              {identityUser.name} {identityUser.firstname}
            </h2>
            <span className={classes.typeUserBarLatOpen}>
              {identityUser.role}
            </span>
            <span className={classes.EntrepriseBarLatOpen}>
              {identityUser.ent}
            </span>
            <Divider className={classes.separatorRightBar} />
            <div className={classes.langDiv}>
              <IconButton onClick={() => changeLanguage("fr")}>
                <img src={drapeauFr} alt="drapeau FR" />
              </IconButton>
              <IconButton onClick={() => changeLanguage("en")}>
                <img src={drapeauEn} alt="drapeau EN" />
              </IconButton>
              {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
            <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
            </div>
            <Divider className={classes.separatorRightBar} />
            <span className={classes.labelRightBar}>filtres de recherche</span>
            <FormControlLabel
              className={classes.switchFilter}
              control={
                <IOSSwitch
                  // className={classes.switchFilter}
                  checked={checked}
                  onChange={handleChangeCollapse}
                />
              }
              label={checked ? "On" : "Off"}
            />
            <Button
              variant="contained"
              className={classes.btnLogout}
              endIcon={<img src={logoutIcon} alt="logout" />}
              onClick={() => logout()}
            >
              Logout
            </Button>
            {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
          </Drawer>
        ) : (
          <Drawer
            className={classes.barreLatDroiteClose}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <Button
              className={classes.btnOpenNavDroite}
              onClick={(e) => toggleRightBarPanel(false)}
            >
              {" "}
              <img
                src={imgbtnSettings}
                alt="settings"
                className={classes.imgbtnset}
              />{" "}
            </Button>
            <span className={classes.userSideTxt}></span>
            {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
          </Drawer>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {stateForm.titleAddOrUpdate}
          </DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <FormControl component="fieldset" className={classes.formGrp}>
              <FormGroup aria-label="position" row>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="nomSite"
                    label={t("SiteTab.TableCell.nomS") + "*"}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.nomSite}
                    onChange={handleChange("nomSite")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="adresseSite"
                    label={t("SiteTab.TableCell.adrS")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.adresseSite}
                    onChange={handleChange("adresseSite")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="paysSite"
                    label={t("SiteTab.TableCell.paysS")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.paysSite}
                    onChange={handleChange("paysSite")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="villeSite"
                    label={t("SiteTab.TableCell.villeSite")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.villeSite}
                    onChange={handleChange("villeSite")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="codePostalSite"
                    label={t("SiteTab.TableCell.codePostalSite")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.codePostalSite}
                    onChange={handleChange("codePostalSite")}
                  />
                </div>
                <FormControl variant="outlined" className={classes.divForm}>
                  {/* <InputLabel id="labelentU" className={classes.labelSelect}>{t('SiteTab.TableCell.entS')}</InputLabel> */}

                  <Autocomplete
                    value={stateForm.entrepriseSite}
                    onChange={(event, newValue) => {
                      // console.log(newValue)
                      // console.log(newValue.inputValue)
                      if (newValue && newValue.inputValue) {
                        handleChangeEnt(newValue.inputValue);
                        return;
                      }
                      handleChangeEnt(newValue);
                    }}
                    id="entrepriseSite"
                    className={classes.textField}
                    options={entreprise.listEnt.map(
                      (option) => option.nomEntreprise
                    )}
                    // getOptionLabel={option => option.nomEntreprise}
                    // onChange={handleChange('entrepriseSite')}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("SiteTab.TableCell.entS") + "*"}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isToggled}
                      onClick={handleChangeLocked}
                      color="primary"
                    />
                  }
                  label={t("SiteTab.TableCell.locked")}
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>

            <Collapse in={openMsgErrEmpty}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrEmpty(false);
                }}
              >
                {t("error.errorMsgEmpty")}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("SiteTab.annuler")}
            </Button>
            <Button onClick={handleValidate} color="primary">
              {t("SiteTab.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSupp}
          onClose={handleCloseSupp}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{t("Buttons.suppr")}</DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <DialogContentText>{t("SiteTab.confirmSupp")}</DialogContentText>
            <DialogActions>
              <Button onClick={handleCloseSupp} color="primary">
                {t("Buttons.non")}
              </Button>
              <Button onClick={clickSupp} color="primary">
                {t("Buttons.oui")}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

// SiteTable.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default SiteTable;
