import React from 'react'
import DashboardComp from '../Components/DashboardComp';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    backgroundPage: {
        backgroundColor: "#F2F2F2",
        paddingBottom: "20px",
    }
})

function Dashboard() {
    const classes = useStyles();
    return (
        <div className={classes.backgroundPage}>
            <DashboardComp />
        </div>
    )
}

export default Dashboard