import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n'
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store/index";
import { Provider } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import qs from 'qs'


const _clientID = "piinkHub";
console.log("piinkdocker/piinkHub:v1.0.9")
var axios_customUrl
if (process.env.NODE_ENV === 'production') {
  axios_customUrl = "https://auth.piink-teknology.com"
  console.log = function () { };
} else {
  // axios_customUrl = "http://192.168.1.13:3004"
  axios_customUrl = "https://auth.piink-teknology.com"
}
// const originRoute = window.location.origin

let tokenCookie = cookie.get("token");

const render = () => {
  ReactDOM.render(<Provider store={store}>
    <App />
  </Provider>, document.getElementById('root')
  );
}

if (tokenCookie) {
  // console.log("test token cookie ok")
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokenCookie}`;
  axios.defaults.baseURL = axios_customUrl
  axios.get('/user').then(res => {
    store.dispatch({ type: "SET_LOGIN", payload: res.data });
    render();
  });
} else {
  if (window.location.search !== "") {
    const params = new URLSearchParams(window.location.search);
    var authorizationCode = params.get('authorizationCode')
    console.log(authorizationCode)
    var tokenRequestObj = {
      grant_type: 'authorization_code',
      code: authorizationCode,
      client_id: _clientID
    }

    axios.post(axios_customUrl + '/api/oauth/authorize/token',
      qs.stringify(tokenRequestObj)
    ).then(res => {
      console.log("create token")
      var token = res.data.access_token
      cookie.set("token", token)
      //recuperer info user

      axios.get(axios_customUrl + '/api/user', {
        params: {
          access_token: res.data.access_token,
          client_id: _clientID
        }
      }).then(userInfo => {
        //stocker vals
        cookie.set("infosUser", userInfo.data.username + "__" + userInfo.data.role)
        //add redirection

        render()
      })

    })


  } else {
    const requestObj = {
      response_type: 'code',
      client_id: _clientID,
      redirect_uri: "/dashboard"
    }
    // console.log("call 1")
    axios.defaults.baseURL = axios_customUrl
    axios.get('/Auth', {
      params: requestObj
    }).then(res => {
      render()
    });


  }
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
