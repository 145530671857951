import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NavBar from "../Components/NavBar";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import imgModif from "../static/images/modifier.png";
import imgSupp from "../static/images/supprimer.png";
import imgResetFilters from "../static/images/Composant5–1.png";

import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import InputLabel from "@material-ui/core/InputLabel";
import { Select, MenuItem, CircularProgress } from "@material-ui/core";
import qs from "qs";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
// import imgbtnOpen from '../static/images/open.png'
import imgbtnSettings from "../static/images/settings.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";

import cookie from "js-cookie";

import axios from "../plugins/axios";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
  body: {
    display: "inline-flex",
    width: "100%",
  },
  rootwNavOpen: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  table: {
    // minWidth: 1300,
    overflow: "auto",
    width: "100%",
  },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "100%",
    marginBottom: "11px",
  },
  selectBox: {
    width: "100%",
    marginTop: "9.5px",
    marginBottom: "8px",
  },
  divForm: {
    width: "100%",
  },
  columnEdit: {
    width: "46%",
    padding: "10px",
  },
  dialogDetail: {
    width: "550px",
  },
  tableCell: {
    textAlign: "center",
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  btnAdd: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  formGrp1: {
    width: "100%",
    height: "280px",
  },
  formGrp2: {
    width: "100%",
    height: "200px",
  },
  rightSideEntete: {
    width: "35%",
    // minWidth: "700px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  btnsReinit: {
    marginTop: "15px",
    textAlign: "end",
  },
  volumeTotalDiv: {
    width: "319px",
    height: "108px",
    margin: "40px",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnOpenNavDroite: {
    // margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
  filtrageRow: {
    backgroundColor: "#F2F2F2",
  },
  txtfield: {
    width: "100%",
    backgroundColor: "#FFF",
    height: "40px",
  },
  txtfieldD: {
    width: "100%",
    backgroundColor: "#FFF",
    marginTop: "0px",
    marginBottom: "0px",
  },
  txtfieldDate: {
    // position: "absolute",
    // height:"5px",
    zIndex: "1",
    // marginTop: "-8px",
    // marginLeft: "5px"
    // color: "#3f51b5"
  },
  tableCellDate: {
    padding: "0px",
  },
  btnOneReinit: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  btnTwoReinit: {
    backgroundColor: "#25C2F2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  // labelSelect: {
  //     position: "absolute",
  //     paddingLeft: "10px",
  //     marginTop: '19px',
  // }
});

function UserTable(props) {
  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://192.168.1.13:3004"
    axios_customUrl = "https://auth.piink-teknology.com";
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [users, setUsers] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [stateForm, setStateForm] = React.useState({
    _id: "",
    emailU: "",
    nomU: "",
    prenomU: "",
    pwdU: "",
    typeU: "",
    entU: "",
    siteU: [],
    partname: [],
    prefLang: "",
    prefUnitMes: "",
    appU: [],
    titleAddOrUpdate: t("DeviceTab.Button.add"),
    addOrUpdate: false,
  });
  const [stateFormFilter, setStateFormFilter] = React.useState({
    emailU: "",
    nomU: "",
    prenomU: "",
    pwdU: "",
    typeU: "",
    entU: "",
    siteU: "",
    prefLang: "",
    prefUnitMes: "",
    appU: "",
  });
  const [rowToSupp, setRowToSupp] = React.useState("");
  const [stateFilter, setStateFilter] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [filterOn, setFilterOnOff] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    entriesLength: 0,
  });
  const [entreprise, setEntreprise] = React.useState({
    listEnt: [],
    entSelected: "",
  });
  const [site, setSite] = React.useState([]);
  const [allSite, setAllSite] = React.useState([]);
  const [device, setDeviceSelected] = React.useState({
    listDevice: [],
    deviceSelected: "",
  });
  const [apps, setApps] = React.useState([]);
  const [updateclicked, setUpdate] = React.useState(false);
  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
    parts: [],
  });
  const [arrayEntAndSites, setArrayEntAndSites] = React.useState([]);

  const classes = useStyles();

  // const changeLanguage = lng => {
  //     i18n.changeLanguage(lng);
  // }

  const [rightBarState, setRightBarState] = React.useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);
  const [checked, setChecked] = React.useState(true);

  const [firstCall, setFirstCall] = React.useState(true);

  const [isDataCharging, setIsDataCharging] = React.useState(false);

  // const monthFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
  // const monthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");

  //handle errors
  const [openMsgErrEmpty, setOpenMsgErrEmpty] = React.useState(false);
  const [openMsgErrMailExist, setOpenMsgErrMailExist] = React.useState(false);
  const [openErrEmail, setOpenErrEmail] = React.useState(false);

  var loc = "";
  function logout() {
    // console.log(inf)
    // var wiioauthTok = cookie.get("wiioauth")
    // console.log(token)
    // console.log(wiioauthTok)
    cookie.remove("token");
    cookie.remove("infosUser");

    //test logout wiioauth redirect don't wwork
    loc = axios_customUrl + "/logout?client_id=piinkHub&token=" + token;

    window.location = loc;
  }

  useEffect(() => {
    if (
      stateFormFilter.emailU ||
      stateFormFilter.nomU ||
      stateFormFilter.prenomU ||
      stateFormFilter.typeU ||
      stateFormFilter.entU ||
      stateFormFilter.siteU ||
      stateFormFilter.prefLang ||
      stateFormFilter.prefUnitMes ||
      stateFormFilter.appU
    ) {
      //si date et date2 alors test que date < date2
      // console.log(stateFormFilter.date)
      // console.log(new Date(stateFormFilter.date))
      // console.log("call filter")
      callFilter();
    } else {
      // console.log("call Simple")
      callSimple();
    }
  }, [stateFormFilter]);

  useEffect(() => {
    async function fetchData() {
      var response = {};
      setIsDataCharging(true);
      var usersInfo = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );
      // console.log(usersInfo.data.sitePartenaire)
      if (
        !usersInfo.data.sitePartenaire.includes(
          usersInfo.data.entrepriseUser._id
        )
      ) {
        // console.log("add ent")
        usersInfo.data.sitePartenaire.push(usersInfo.data.entrepriseUser._id);
      }
      // console.log(usersInfo.data.sitePartenaire)

      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
        parts: usersInfo.data.sitePartenaire,
      });

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }

      var responseSite = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site"
      );
      setSite(responseSite.data);
      setAllSite(responseSite.data);
      // var responsepart = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/device')
      // setDeviceSelected({ listDevice: responsepart.data })

      getListDevice();

      if (infsplit[1] === "adminPiink") {
        //get Apps
        var allApps = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/app"
        );
        setApps(allApps.data);

        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/count"
        );
        setPagination({ entriesLength: response3.data });

        var responseEnt = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company"
        );
        setEntreprise({ listEnt: responseEnt.data });
      } else if (infsplit[1] === "partenaire") {
        //get Apps
        var listIdApp = [];
        usersInfo.data.appArray.forEach((elt) => {
          listIdApp.push(elt._id);
        });
        var allApps = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/app",
          {
            params: {
              appArray: listIdApp,
            },
          }
        );

        setApps(allApps.data);

        if (
          usersInfo.data.sitePartenaire !== undefined &&
          usersInfo.data.sitePartenaire !== null
        ) {
          //get users
          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/getUsersFromPart",
            {
              params: {
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );

          //get user count
          var response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/count",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );
          setPagination({ entriesLength: response3.data });

          // console.log(usersInfo.data.sitePartenaire)

          //get compnanies
          var responseEnt = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL +
              "/company/getCompaniesForPartenaire",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );
          setEntreprise({ listEnt: responseEnt.data });
        }
      } else {
        var listIdApp = [];
        usersInfo.data.appArray.forEach((elt) => {
          listIdApp.push(elt._id);
        });
        var allApps = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/app",
          {
            params: {
              appArray: listIdApp,
            },
          }
        );

        setApps(allApps.data);

        var listSiteUser = usersInfo.data.siteUser;
        // console.log(listSiteUser)

        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });

        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersBySite",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
              siteUser: listID,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/count",
          {
            params: {
              siteUser: listID,
            },
          }
        );
        setPagination({ entriesLength: response3.data });

        var responseEnt = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company/getFromEntName",
          {
            params: {
              nomEntreprise: usersInfo.data.entrepriseUser,
            },
          }
        );
        // console.log(responseEnt.data)
        setEntreprise({ listEnt: [responseEnt.data] });
      }

      var users = response.data;
      console.log(users);

      setUsers(users);

      // // console.log("renderOptionPartenaire")
      // var arrayEntSites = []
      // //create list company/sites
      // for (var i = 0; i < responseEnt.data.length; i++){
      //     //get sites from a company
      //     var sitesEnt = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/site/getSiteFromEnt', {
      //         params: {
      //             entId: responseEnt.data[i].nomEntreprise
      //         }
      //     })
      //     arrayEntSites.push({ value: "ent", name: responseEnt.data[i].nomEntreprise, sites: sitesEnt.data})
      //         // sitesEnt.data.forEach(elt => {
      //         //     arrayEntSites.push({ value: elt._id, name: elt.nomSite})
      //         // });

      // }
      // setArrayEntAndSites(arrayEntSites)
      // console.log(arrayEntSites)

      //init list entreprise
      // console.log(infsplit[1])
      // if(infsplit[1] !== "user"){
      //     // console.log("not user")
      //     var responseEnt = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + "/company")
      //     setEntreprise({ listEnt: responseEnt.data })
      // }else {
      //     console.log(usersInfo.data.entrepriseUser)
      //     var responseEnt = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + "/company/getFromEntName", {
      //         params: {
      //             nomEntreprise: usersInfo.data.entrepriseUser
      //         }
      //     })
      //     // console.log(responseEnt.data)
      //     setEntreprise({ listEnt: [responseEnt.data] })
      // }

      setFirstCall(false);
      setIsDataCharging(false);
    }

    fetchData();
  }, []);

  const callBackNav = (navData) => {
    setIsNavBarOpen(navData);
    // console.log(navData)
  };

  const handleChangeCollapse = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeFilter = (name) => (event) => {
    setStateFormFilter({ ...stateFormFilter, [name]: event.target.value });
    // console.log(stateFormFilter)
    //wait change then -> callFilter()
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  const handleChangeEnt = (value) => {
    console.log(value);
    // setStateForm({ ...stateForm, siteU: [] })
    stateForm.siteU = [];
    setStateForm({ ...stateForm, entU: value });
    updateSiteChoice(value);
  };

  const handleChangeSite = (value) => {
    // console.log(stateForm)
    setStateForm({ ...stateForm, siteU: value });
  };

  const handleChangeAppArray = (value) => {
    // console.log(apps)
    setStateForm({ ...stateForm, appU: value });
  };

  const handleChangeListPart = (value) => {
    setStateForm({ ...stateForm, partname: value });
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }
    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }
  //definition du swicth
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#eb4034",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleChange = (name) => (event) => {
    // console.log("change field popup")
    // console.log(event.target.value)
    setStateForm({ ...stateForm, [name]: event.target.value });
    if (name === "entU") {
      updateSiteChoice(event.target.value);
    }
    // if(name === "siteU"){
    //     console.log(event.target.value)
    // }
  };

  // const handleChangeMultiple = (event) => {
  //     console.log(site.listSite)
  //     console.log(event.target.value)
  //     console.log(site.siteSelected)

  //     if(site.siteSelected.includes(event.target.value)){
  //         //supprimer l'element de la liste
  //         var indToDel = site.siteSelected.indexOf(event.target.value)
  //         site.siteSelected.splice(site.siteSelected[indToDel])
  //     } else {
  //         site.siteSelected.push(event.target.value[0])
  //     }
  //     // setSite({ siteSelected : })

  //     // const { options } = site.listSite;
  //     // const value = [];

  //     // for(let i = 0, l = site.listSite.length; i < l; i += 1){
  //     //     if(site.listSite[i].selected){
  //     //         value.push(site.listSite[i].value);
  //     //     }
  //     // }
  //     // console.log(value)
  //     setStateForm({ ...stateForm, "siteU": site.siteSelected })

  // }

  async function updateSiteChoice(entU) {
    console.log("update choice");
    // console.log(stateForm.entU)
    console.log(entU);
    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/site/getSiteFromEnt",
      {
        params: {
          entId: entU,
        },
      }
    );
    console.log("update choice response =>");
    console.log(response.data);
    setSite(response.data);
  }

  async function handleChangePage(event, newPage) {
    // setIsDataCharging(true)
    // console.log(newPage)
    setPage(newPage);

    if (filterOn) {
      // console.log("filter ON")
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);
      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = newPage;
      paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

      var response = {};

      if (infsplit[1] === "user") {
        paramsFilter.siteUser = identityUser.sites;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      // call measure table with updated params
      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/userFilter",
        {
          params: paramsFilter,
        }
      );

      var users = response.data;
      setUsers(users);
    } else {
      // console.log("filter OFF")
      var response = {};
      if (infsplit[1] === "adminPiink") {
        // console.log("admin")
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user",
          {
            params: {
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        // console.log("part")
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersFromPart",
          {
            params: {
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        // console.log("user")
        var listSiteUser = identityUser.sites;
        // console.log(listSiteUser)

        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });

        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersBySite",
          {
            params: {
              siteUser: listID,
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
      }
      var users = response.data;
      console.log(users);
      setUsers(users);
    }
    // setIsDataCharging(false)
    console.log("stop charging");
  }

  async function handleChangeRowsPerPage(event, newRowPerPage) {
    setIsDataCharging(true);
    // console.log(newRowPerPage.props.value)
    setRowsPerPage(newRowPerPage.props.value);
    setPage(0);

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = 0;
      paramsFilter.nbMeasureDisplay = newRowPerPage.props.value + 1;

      var response = {};
      if (infsplit[1] === "user") {
        paramsFilter.siteUser = identityUser.sites;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/userFilter",
        {
          params: paramsFilter,
        }
      );

      var users = response.data;
      setUsers(users);
    } else {
      var response = {};
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersFromPart",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        var listSiteUser = identityUser.sites;
        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });

        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersBySite",
          {
            params: {
              siteUser: listID,
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      }
      var users = response.data;
      setUsers(users);
    }
    setIsDataCharging(false);
    // call measure table with updated params
  }

  async function clickAdd(row) {
    console.log(row);

    if (row === undefined) {
      stateForm.emailU = "";
      stateForm.nomU = "";
      stateForm.prenomU = "";
      stateForm.pwdU = "";
      stateForm.typeU = "";
      stateForm.entU = "";
      stateForm.siteU = [];
      stateForm.partname = [];
      stateForm.prefLang = "";
      stateForm.prefUnitMes = "";
      stateForm.appU = [];
      stateForm.titleAddOrUpdate = t("Buttons.add");
      stateForm.addOrUpdate = false;
    } else {
      stateForm._id = row._id;
      stateForm.emailU = row.emailUser;
      stateForm.nomU = row.nomUser;
      stateForm.prenomU = row.prenomUser;
      stateForm.pwdU = row.passwordUser;
      stateForm.typeU = row.typeUser;
      stateForm.entU = row.entrepriseUser.nomEntreprise;
      stateForm.partname = row.sitePartenaire;

      // updateSiteChoice(row.entrepriseUser.nomEntreprise)
      if (row.siteUser !== undefined && row.siteUser !== "") {
        var response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/site/getSiteFromEnt",
          {
            params: {
              entId: row.entrepriseUser.nomEntreprise,
            },
          }
        );

        var tabIndex = [];
        // console.log("site :")
        // console.log(site)
        // console.log("row.siteUser :")
        // console.log(row.siteUser[0].nomSite)
        var tabIndex = [];
        row.siteUser.forEach((elt) => {
          for (var i = 0; i < response.data.length; i++) {
            // console.log(elt._id + " === " + site[i]._id)
            if (elt._id === response.data[i]._id) {
              // console.log(i)
              // console.log(site.listSite[i])
              tabIndex.push(response.data[i]);
            }
          }
        });
        setSite(response.data);
        // console.log(tabIndex)
        stateForm.siteU = tabIndex;

        // var listS = row.siteUser[0].split(", ")
        // for(let i=0;i<allSite.length;i++){
        //     //get site from name then push it into stateFrom
        //     if (listS.includes(allSite[i].nomSite)){
        //         stateForm.siteU.push(allSite[i])
        //     }

        // }
      }
      if (row.appArray !== undefined && row.appArray !== "") {
        // stateForm.appU = row.appArray
        //get index pour chaque app dans apps
        var tabIndex = [];
        row.appArray.forEach((elt) => {
          for (var i = 0; i < apps.length; i++) {
            if (elt._id === apps[i]._id) {
              // console.log(i)
              tabIndex.push(apps[i]);
            }
          }
        });
        stateForm.appU = tabIndex;
      }
      if (row.sitePartenaire !== undefined && row.sitePartenaire !== "") {
        var tabIndex = [];
        console.log("in row.sitePartenaire");
        row.sitePartenaire.forEach((elt) => {
          for (var i = 0; i < entreprise.listEnt.length; i++) {
            // console.log(entreprise.listEnt[i]._id)
            // console.log(elt)
            if (elt === entreprise.listEnt[i]._id) {
              // console.log(i)
              tabIndex.push(entreprise.listEnt[i]);
            }
          }
        });
        stateForm.partname = tabIndex;
      }
      console.log("stateForm.siteU");
      console.log(stateForm.siteU);
      // stateForm.siteU =  row.siteUser
      // stateForm.partname = row.partName
      stateForm.prefLang = row.prefLang;
      stateForm.prefUnitMes = row.prefUnitMes;
      stateForm.titleAddOrUpdate = t("Buttons.update");
      stateForm.addOrUpdate = true;
    }
    handleClickOpen();
    // console.log(row.siteUser)
    console.log(stateForm);
  }

  function clickUpdate(row) {
    setUpdate(true);
    // console.log(row)
    clickAdd(row);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenSupp = (row) => {
    setRowToSupp(row);
    setOpenSupp(true);
  };

  const handleClose = () => {
    setOpen(false);
    stateForm._id = "";
    stateForm.emailU = "";
    stateForm.nomU = "";
    stateForm.prenomU = "";
    stateForm.pwdU = "";
    stateForm.typeU = "";
    stateForm.entU = "";
    stateForm.siteU = [];
    stateForm.appU = [];
    stateForm.partname = [];
    stateForm.prefLang = "";
    stateForm.prefUnitMes = "";
    stateForm.titleAddOrUpdate = t("Buttons.add");
    stateForm.addOrUpdate = false;
    setUpdate(false);
  };

  const handleCloseSupp = () => {
    setRowToSupp("");
    setOpenSupp(false);
  };

  // function setDateString() {
  //     var dateToday = new Date()
  //     var day = dateToday.getDate()
  //     var month = dateToday.getUTCMonth()
  //     var year = dateToday.getFullYear()

  //     var dateOfTodayVar = ""
  //     if (i18n.language === "fr") {
  //         month = monthFr[month]
  //     } else if (i18n.language === "en") {
  //         month = monthEn[month]
  //     }
  //     dateOfTodayVar = day + " " + month + " " + year
  //     // console.log(dateOfTodayVar)
  //     return (dateOfTodayVar)

  // }
  function checkFieldsNotEmpty() {
    if (
      stateForm.emailU !== "" &&
      stateForm.emailU !== undefined &&
      stateForm.nomU !== "" &&
      stateForm.nomU !== undefined &&
      stateForm.prenomU !== "" &&
      stateForm.prenomU !== undefined &&
      stateForm.pwdU !== "" &&
      stateForm.pwdU !== undefined &&
      stateForm.typeU !== "" &&
      stateForm.typeU !== undefined &&
      stateForm.entU !== "" &&
      stateForm.entU !== undefined &&
      stateForm.siteU.length !== [] &&
      stateForm.siteU !== undefined &&
      stateForm.appU !== [] &&
      stateForm.appU !== undefined
    ) {
      // console.log("in first true")
      return true;
    } else {
      // console.log("in the first else")
      if (
        stateForm.emailU !== "" &&
        stateForm.emailU !== undefined &&
        stateForm.nomU !== "" &&
        stateForm.nomU !== undefined &&
        stateForm.prenomU !== "" &&
        stateForm.prenomU !== undefined &&
        stateForm.typeU !== "" &&
        stateForm.typeU !== undefined &&
        stateForm.entU !== "" &&
        stateForm.entU !== undefined &&
        stateForm.siteU.length !== 0 &&
        stateForm.siteU !== undefined &&
        stateForm.appU !== [] &&
        stateForm.appU !== undefined &&
        stateForm.titleAddOrUpdate === t("Buttons.update")
      ) {
        // console.log("in seconde true")
        return true;
      }
    }
    // console.log("FALSE")
    return false;
  }

  function checkEmailField(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      return true;
    }
    return false;
  }

  async function handleValidate() {
    // console.log(stateForm)
    // console.log("site")
    // console.log(site)
    var fieldsNotEmpty = checkFieldsNotEmpty();
    var fieldEmail = checkEmailField(stateForm.emailU);
    console.log("fieldsNotEmpty");
    console.log(fieldsNotEmpty);
    if (fieldsNotEmpty) {
      if (fieldEmail) {
        // console.log("GO UPDATE !!!!")
        // console.log(stateForm)
        //test if email already exist
        var emailExist = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/EmailExist",
          {
            params: {
              email: stateForm.emailU,
            },
          }
        );

        //test if it's add or update
        if (emailExist.data && !updateclicked) {
          //error
          console.log("error");
          setOpenMsgErrMailExist(true);
          return;
        } else {
          //post user piinkhub bdd
          var sitesUSelected = [];

          var tab1 = stateForm.siteU;
          var tab2 = site;
          console.log(tab1);
          console.log(tab2);
          for (var i = 0; i < tab1.length; i++) {
            for (var j = 0; j < tab2.length; j++) {
              if (tab1[i].nomSite === tab2[j].nomSite) {
                sitesUSelected.push(tab2[j]._id);
              }
            }
          }

          // console.log("sitesUSelected =>")
          // console.log(sitesUSelected)

          console.log(stateForm.entU);
          var entId = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/company/getIdFromName",
            {
              params: {
                entName: stateForm.entU,
              },
            }
          );
          // console.log(entId.data[0]._id)

          if (!updateclicked) {
            await axios.defaultAxios.post(
              axios.defaultAxios.defaults.baseURL + "/user",
              {
                emailUser: stateForm.emailU,
                nomUser: stateForm.nomU,
                prenomUser: stateForm.prenomU,
                passwordUser: "",
                typeUser: stateForm.typeU,
                entrepriseUser: entId.data[0]._id,
                siteUser: sitesUSelected,
                prefLang: stateForm.prefLang,
                prefUnitMes: stateForm.prefUnitMes,
                appArray: stateForm.appU,
                sitePartenaire: stateForm.partname,
              }
            );
          } else {
            // console.log(stateForm._id)
            await axios.defaultAxios.put(
              axios.defaultAxios.defaults.baseURL + "/user",
              {
                _id: stateForm._id,
                emailUser: stateForm.emailU,
                nomUser: stateForm.nomU,
                prenomUser: stateForm.prenomU,
                passwordUser: "",
                typeUser: stateForm.typeU,
                entrepriseUser: entId.data[0]._id,
                siteUser: sitesUSelected,
                prefLang: stateForm.prefLang,
                prefUnitMes: stateForm.prefUnitMes,
                appArray: stateForm.appU,
                sitePartenaire: stateForm.partname,
              }
            );
          }

          const config = {
            baseURL: axios_customUrl,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };

          //test if password has been updated
          if (stateForm.pwdU !== "") {
            var tempObj = {
              username: stateForm.emailU,
              password: stateForm.pwdU,
              client_id: "piinkHub",
              role: stateForm.typeU,
            };
            //update user auth bdd
            var authResponse = await axios.defaultAxios.post(
              "/api/user",
              qs.stringify(tempObj),
              config
            );
          } else {
            var tempObj = {
              username: stateForm.emailU,
              password: "",
              client_id: "piinkHub",
              role: stateForm.typeU,
            };
            //update user auth bdd
            var authResponse = await axios.defaultAxios.post(
              "/api/user",
              qs.stringify(tempObj),
              config
            );
          }

          // await axios.defaultAxios.post(axios.defaultAxios.defaults.baseURL + '/user', {
          //     emailUser: stateForm.emailU,
          //     nomUser: stateForm.nomU,
          //     prenomUser: stateForm.prenomU,
          //     passwordUser: stateForm.pwdU,
          //     typeUser: stateForm.typeU,
          //     entrepriseUser: stateForm.entU,
          //     siteUser: stateForm.siteU
          // })

          //if device selected recup le partname et l'associé dans la table assocpart
          // if (stateForm.typeU === "partenaire") {
          //     //create assocPart
          //     await axios.defaultAxios.post(axios.defaultAxios.defaults.baseURL + '/assocpart', {
          //         deviceName: stateForm.partname,
          //         emailUser: stateForm.emailU
          //     })
          // }
        }

        if (infsplit[1] === "adminPiink") {
          var response2 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user",
            {
              params: {
                nbOfPage: 0,
                nbMeasureDisplay: rowsPerPage + 1,
              },
            }
          );
          setUsers(response2.data);

          var response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/count"
          );
          setPagination({ entriesLength: response3.data });
        } else if (infsplit[1] === "partenaire") {
          var response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/getUsersFromPart",
            {
              params: {
                nbOfPage: 0,
                nbMeasureDisplay: rowsPerPage + 1,
                listPartenaire: identityUser.parts,
              },
            }
          );
          setUsers(response.data);
          var response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/count",
            {
              params: {
                listPartenaire: identityUser.parts,
              },
            }
          );
          setPagination({ entriesLength: response3.data });
        } else {
          var listSiteUser = identityUser.sites;
          var listID = [];
          listSiteUser.forEach((elt) => {
            listID.push(elt._id);
          });

          var response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/getUsersBySite",
            {
              params: {
                nbOfPage: 0,
                nbMeasureDisplay: rowsPerPage + 1,
                siteUser: listID,
              },
            }
          );
          setUsers(response.data);
          var response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/user/count",
            {
              params: {
                siteUser: listID,
              },
            }
          );
          setPagination({ entriesLength: response3.data });
        }

        handleClose();
      } else {
        setOpenErrEmail(true);
      }
    } else {
      setOpenMsgErrEmpty(true);
    }
  }

  async function clickSupp() {
    // console.log(rowToSupp)
    await axios.defaultAxios.delete(
      axios.defaultAxios.defaults.baseURL + "/user",
      {
        data: {
          emailUser: rowToSupp.emailUser,
        },
      }
    );

    var response2 = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/user"
    );
    setUsers(response2.data);

    handleCloseSupp();
  }

  function renderTypeUser() {
    switch (identityUser.role) {
      case "adminPiink":
        return (
          <Select
            id="typeU"
            labelId="labelTypeUser"
            margin="dense"
            className={classes.selectBox}
            variant="outlined"
            value={stateForm.typeU}
            onChange={handleChange("typeU")}
          >
            {/* <MenuItem aria-label="None" value=""> <em>None</em> </MenuItem> */}
            <MenuItem value="adminPiink">adminPiink</MenuItem>
            <MenuItem value="partenaire">partenaire</MenuItem>
            <MenuItem value="user">user</MenuItem>
          </Select>
        );

      case "partenaire":
        return (
          <Select
            id="typeU"
            labelId="labelTypeUser"
            margin="dense"
            className={classes.selectBox}
            variant="outlined"
            value={stateForm.typeU}
            onChange={handleChange("typeU")}
          >
            {/* <MenuItem aria-label="None" value=""> <em>None</em> </MenuItem> */}
            <MenuItem value="partenaire">partenaire</MenuItem>
            <MenuItem value="user">user</MenuItem>
          </Select>
        );

      case "user":
        return (
          <Select
            id="typeU"
            labelId="labelTypeUser"
            margin="dense"
            className={classes.selectBox}
            variant="outlined"
            value={stateForm.typeU}
            onChange={handleChange("typeU")}
          >
            {/* <MenuItem aria-label="None" value=""> <em>None</em> </MenuItem> */}
            <MenuItem value="user">user</MenuItem>
          </Select>
        );
    }
  }

  // function renderOPtionEnt() {
  //     return entreprise.listEnt.map((ent,i) => {
  //         return (
  //             <MenuItem
  //                 label="ent"
  //                 value={ent.nomEntreprise}
  //                 key={i}
  //                 name={ent.nomEntreprise}>{ent.nomEntreprise}</MenuItem>
  //         );
  //     });
  // }

  // function renderOPtionSite() {
  //     return site.map((sit, i) => {
  //         return (
  //             <MenuItem
  //                 id={sit.id}
  //                 label="sit"
  //                 value={sit.nomSite}
  //                 key={i}
  //                 name={sit.nomSite}>{sit.nomSite}</MenuItem>
  //         );
  //     });
  // }

  // function renderOptionDevice(){
  //     return device.listDevice.map((part,i) => {
  //         return (
  //             <MenuItem
  //                 label="part"
  //                 value={part.deviceName}
  //                 key={i}
  //                 name={part.deviceName}>{part.deviceName}</MenuItem>
  //         );
  //     });
  // }

  // function renderOptionPartenaire() {
  //     return arrayEntAndSites.map((arr,i) => {
  //         return(
  //             <optgroup key={i} label={arr.name}>
  //                 {
  //                     renderOptionSiteInEnt(arr.sites)
  //                     // <option value={arr.sites[0]._id}>{arr.sites[0].nomSite}</option>
  //                 }
  //             </optgroup>
  //         )

  //     })
  // }

  // function renderOptionSiteInEnt(sites) {
  //     return sites.map((site, j) => {
  //         return(
  //             <option key={j} value={site._id}>{site.nomSite}</option>
  //         )
  //     })
  // }

  // function renderOptionApps(){
  //     return apps.map((app,i) => {
  //         return (
  //             <MenuItem
  //                 label="app"
  //                 value={app.appName}
  //                 key={i}
  //                 name={app.appName}>{app.appName}</MenuItem>
  //         );
  //     })
  // }

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  // async function callRefresh(){
  //     setStateFormFilter({
  //         emailU: "",
  //         nomU: "",
  //         prenomU: "",
  //         pwdU: "",
  //         typeU: "",
  //         entU: "",
  //         siteU: "",
  //         prefLang: "",
  //         prefUnitMes: "",
  //         partname: "",
  //         appU: ""
  //     })
  //     // callSimple()
  // }

  async function ResetFilters() {
    setStateFormFilter({
      emailU: "",
      nomU: "",
      prenomU: "",
      pwdU: "",
      typeU: "",
      entU: "",
      siteU: "",
      prefLang: "",
      prefUnitMes: "",
      partname: "",
      appU: "",
    });
    // callSimple()
  }

  async function callFilter() {
    setIsDataCharging(true);
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);
    setPage(0);
    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = 0;
    paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

    if (infsplit[1] === "user") {
      var listID = [];
      identityUser.sites.forEach((elt) => {
        listID.push(elt._id);
      });
      paramsFilter.siteUser = listID;
    } else if (infsplit[1] === "partenaire") {
      // console.log("device filter added")
      // console.log(identityUser.parts)
      paramsFilter.listPartenaire = identityUser.parts;
    }

    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/user/userFilter",
      {
        params: paramsFilter,
      }
    );

    setFilterOnOff(true);
    setUsers(response.data);

    var response3 = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/user/countFilter",
      {
        params: paramsFilter,
      }
    );
    setPagination({ entriesLength: response3.data });
    setIsDataCharging(false);
  }

  function pressEnter(ev) {
    if (ev.key === "Enter") {
      callFilter();
      ev.preventDefault();
    }
  }

  async function callSimple() {
    setIsDataCharging(true);
    if (firstCall === false) {
      setStateFilter("");
      setPage(0);
      setFilterOnOff(false);

      var response3 = [];

      var response2 = [];
      if (infsplit[1] === "adminPiink") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/count"
        );
        setPagination({ entriesLength: response3.data });
      } else if (infsplit[1] === "partenaire") {
        // console.log("call get user from call simple")
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersFromPart",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );

        //get user count
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/count",
          {
            params: {
              listPartenaire: identityUser.parts,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      } else {
        var listID = [];
        identityUser.sites.forEach((elt) => {
          listID.push(elt._id);
        });

        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/getUsersBySite",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
              siteUser: listID,
            },
          }
        );
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/user/count",
          {
            params: {
              siteUser: listID,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      }

      setUsers(response2.data);
    }
    setIsDataCharging(false);
  }

  function filterConditions(paramsFilter) {
    if (stateFormFilter.emailU !== "" && stateFormFilter.emailU !== undefined) {
      paramsFilter.emailU = stateFormFilter.emailU; //{ $regex: /789$/ }
    }

    if (stateFormFilter.nomU !== "" && stateFormFilter.nomU !== undefined) {
      paramsFilter.nomU = stateFormFilter.nomU; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.prenomU !== "" &&
      stateFormFilter.prenomU !== undefined
    ) {
      paramsFilter.prenomU = stateFormFilter.prenomU; //{ $regex: /789$/ }
    }

    if (stateFormFilter.typeU !== "" && stateFormFilter.typeU !== undefined) {
      paramsFilter.typeU = stateFormFilter.typeU; //{ $regex: /789$/ }
    }

    if (stateFormFilter.entU !== "" && stateFormFilter.entU !== undefined) {
      paramsFilter.entU = stateFormFilter.entU; //{ $regex: /789$/ }
    }

    if (stateFormFilter.siteU !== "" && stateFormFilter.siteU !== undefined) {
      paramsFilter.siteU = stateFormFilter.siteU; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.prefLang !== "" &&
      stateFormFilter.prefLang !== undefined
    ) {
      paramsFilter.prefLang = stateFormFilter.prefLang;
    }

    if (
      stateFormFilter.prefUnitMes !== "" &&
      stateFormFilter.prefUnitMes !== undefined
    ) {
      paramsFilter.prefUnitMes = stateFormFilter.prefUnitMes;
    }

    if (stateFormFilter.appU !== "" && stateFormFilter.appU !== undefined) {
      paramsFilter.appU = stateFormFilter.appU;
    }

    return paramsFilter;
  }

  async function getListDevice() {
    //if user connected is admin
    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/device/getUniqueDevice"
    );
    setDeviceSelected({ listDevice: response.data });
  }

  // function optionInArrayApp(option){
  //     console.log("test Option")
  //     stateForm.appU.forEach(elt => {
  //         if(elt._id === option._id){
  //             console.log("return TRUE")
  //             return true
  //         }
  //     });
  //     return false
  // }

  return (
    <div className={classes.body}>
      <NavBar
        pageCallBack={callBackNav}
        dataFromParent={"userPage"}
        dataIsUser={identityUser.role}
      />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <div className={classes.entete}>
          <div className={classes.volumeTotalDiv}>
            <h1>{t("UserTab.title")}</h1>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                {identityUser.ent}
              </span>
            </div>
            <Divider />
            <div className={classes.btnsReinit}>
              {identityUser.role !== "user" ? (
                <Button
                  className={classes.btnOneReinit}
                  onClick={() => clickAdd()}
                >
                  {t("Buttons.add")}
                </Button>
              ) : (
                <p></p>
              )}
              {/* <Button
                className={classes.btnTwoReinit}
                onClick={() => ResetFilters()}
              >
                {t("Buttons.resetFilter")}
              </Button> */}
              {/* <Button className={classes.btnOneReinit} onClick={() => resetVolChecked()}>{t('ScanTable.Button.resetVol')}</Button>
                            <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('ScanTable.Button.resetFilter')}</Button> */}
            </div>
          </div>
          {/* <button onClick={() => changeLanguage('fr')}>fr</button>
                    <button onClick={() => changeLanguage('en')}>en</button> */}
          {/* <Button onClick={() => clickUpdate()} className={classes.btnAdd}>{t('Buttons.add')}</Button> */}
        </div>
        <Paper className={classes.tableau}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.emailU")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.nomU")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.prenomU")}
                </TableCell>
                {/* <TableCell>{t('UserTab.TableCell.pwdU')}</TableCell> */}
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.typeU")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.entU")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.siteU")}
                </TableCell>
                {/* <TableCell className={classes.tableCell}>{t('UserTab.TableCell.prefLang')}</TableCell>
                                <TableCell className={classes.tableCell}>{t('UserTab.TableCell.prefUnit')}</TableCell> */}
                <TableCell className={classes.tableCell}>
                  {t("UserTab.TableCell.appArray")}
                </TableCell>
              </TableRow>
              <TableRow className={classes.filtrageRow}>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="emailU"
                      variant="outlined"
                      value={stateFormFilter.emailU}
                      onChange={handleChangeFilter("emailU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="nomU"
                      variant="outlined"
                      value={stateFormFilter.nomU}
                      onChange={handleChangeFilter("nomU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="prenomU"
                      variant="outlined"
                      value={stateFormFilter.prenomU}
                      onChange={handleChangeFilter("prenomU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="typeU"
                      variant="outlined"
                      value={stateFormFilter.typeU}
                      onChange={handleChangeFilter("typeU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="entU"
                      variant="outlined"
                      value={stateFormFilter.entU}
                      onChange={handleChangeFilter("entU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="siteU"
                      variant="outlined"
                      value={stateFormFilter.siteU}
                      onChange={handleChangeFilter("siteU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                {/* <TableCell>
                                    <Collapse in={checked}>
                                        <TextField
                                            className={classes.txtfield}
                                            margin="dense"
                                            id="prefLang"
                                            variant="outlined"
                                            value={stateFormFilter.prefLang}
                                            onChange={handleChangeFilter('prefLang')}
                                            onKeyPress={(ev) => pressEnter(ev)}
                                        />
                                    </Collapse>
                                </TableCell>
                                <TableCell>
                                    <Collapse in={checked}>
                                        <TextField
                                            className={classes.txtfield}
                                            margin="dense"
                                            id="prefUnitMes"
                                            variant="outlined"
                                            value={stateFormFilter.prefUnitMes}
                                            onChange={handleChangeFilter('prefUnitMes')}
                                            onKeyPress={(ev) => pressEnter(ev)}
                                        />
                                    </Collapse>
                                </TableCell> */}
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="appU"
                      variant="outlined"
                      value={stateFormFilter.appU}
                      onChange={handleChangeFilter("appU")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button onClick={() => ResetFilters()}>
                    <img src={imgResetFilters} alt="detail" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataCharging ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                users.map((row) => (
                  <TableRow hover key={row._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.emailUser}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.nomUser}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.prenomUser}
                    </TableCell>
                    {/* <TableCell>{row.passwordUser}</TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {row.typeUser}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.entrepriseUser != null
                        ? row.entrepriseUser.nomEntreprise
                        : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.siteUser.map((site) => (
                        <span>{site.nomSite}, </span>
                      ))}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>{row.prefLang}</TableCell>
                                            <TableCell className={classes.tableCell}>{row.prefUnitMes}</TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {row.appArray.map((app) => (
                        <span>{app.appName}, </span>
                      ))}
                    </TableCell>
                    {identityUser.role !== "user" ||
                    identityUser.mail === row.emailUser ? (
                      <TableCell align="left" className={classes.tableCell}>
                        <Button type="button" onClick={(e) => clickUpdate(row)}>
                          <img src={imgModif} alt="modif" />
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="left"></TableCell>
                    )}
                    {identityUser.role !== "user" ? (
                      <TableCell align="right" className={classes.tableCell}>
                        <Button
                          type="button"
                          onClick={(e) => handleClickOpenSupp(row)}
                        >
                          <img src={imgSupp} alt="suppr" />
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="left"></TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={pagination.entriesLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="users par page :"
          />
        </Paper>

        {rightBarState ? (
          <Drawer
            className={classes.barreLatDroiteOpen}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper2,
            }}
            anchor="right"
          >
            {/* logout */}
            <Button
              className={classes.btnCloseNavDroite}
              onClick={(e) => toggleRightBarPanel(true)}
            >
              {" "}
              <img src={imgbtnClose} alt="close" />{" "}
            </Button>
            v1.0.10
            <h2 className={classes.userNameBarLatOpen}>
              {identityUser.name} {identityUser.firstname}
            </h2>
            <span className={classes.typeUserBarLatOpen}>
              {identityUser.role}
            </span>
            <span className={classes.EntrepriseBarLatOpen}>
              {identityUser.ent}
            </span>
            <Divider className={classes.separatorRightBar} />
            <div className={classes.langDiv}>
              <IconButton onClick={() => changeLanguage("fr")}>
                <img src={drapeauFr} alt="drapeau FR" />
              </IconButton>
              <IconButton onClick={() => changeLanguage("en")}>
                <img src={drapeauEn} alt="drapeau EN" />
              </IconButton>
              {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
            <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
            </div>
            <Divider className={classes.separatorRightBar} />
            <span className={classes.labelRightBar}>filtres de recherche</span>
            <FormControlLabel
              className={classes.switchFilter}
              control={
                <IOSSwitch
                  // className={classes.switchFilter}
                  checked={checked}
                  onChange={handleChangeCollapse}
                />
              }
              label={checked ? "On" : "Off"}
            />
            <Button
              variant="contained"
              className={classes.btnLogout}
              endIcon={<img src={logoutIcon} alt="logout" />}
              onClick={() => logout()}
            >
              Logout
            </Button>
            {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
          </Drawer>
        ) : (
          <Drawer
            className={classes.barreLatDroiteClose}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <Button
              className={classes.btnOpenNavDroite}
              onClick={(e) => toggleRightBarPanel(false)}
            >
              {" "}
              <img
                src={imgbtnSettings}
                alt="settings"
                className={classes.imgbtnset}
              />{" "}
            </Button>
            <span className={classes.userSideTxt}></span>
            {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
          </Drawer>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {stateForm.titleAddOrUpdate}
          </DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <h4>Infos</h4>
            <FormGroup component="fieldset" className={classes.formGrp1}>
              <FormGroup aria-label="position" row>
                <FormGroup aria-label="position" className={classes.columnEdit}>
                  <div className={classes.divForm}>
                    <TextField
                      type="email"
                      margin="dense"
                      id="emailU"
                      label={t("UserTab.TableCell.emailU") + "*"}
                      className={classes.textField}
                      variant="outlined"
                      defaultValue={stateForm.emailU}
                      onChange={handleChange("emailU")}
                      disabled={stateForm.addOrUpdate}
                    />
                  </div>
                  <div className={classes.divForm}>
                    <TextField
                      margin="dense"
                      id="nomU"
                      label={t("UserTab.TableCell.nomU") + "*"}
                      className={classes.textField}
                      variant="outlined"
                      defaultValue={stateForm.nomU}
                      onChange={handleChange("nomU")}
                    />
                  </div>
                  <div className={classes.divForm}>
                    <TextField
                      margin="dense"
                      id="prenomU"
                      label={t("UserTab.TableCell.prenomU") + "*"}
                      className={classes.textField}
                      variant="outlined"
                      defaultValue={stateForm.prenomU}
                      onChange={handleChange("prenomU")}
                    />
                  </div>
                  <div className={classes.divForm}>
                    <TextField
                      margin="dense"
                      id="pwdU"
                      label={t("UserTab.TableCell.pwdU") + "*"}
                      className={classes.textField}
                      variant="outlined"
                      defaultValue={stateForm.pwdU}
                      onChange={handleChange("pwdU")}
                    />
                  </div>
                </FormGroup>
                <FormGroup aria-label="position" className={classes.columnEdit}>
                  <FormControl variant="outlined" className={classes.divForm}>
                    {/* <InputLabel id="labelentU" className={classes.labelSelect}>{t('UserTab.TableCell.entU')}</InputLabel> */}
                    {/* <Select
                                            id="entU"
                                            labelId="labelentU"
                                            margin="dense"
                                            className={classes.selectBox}
                                            variant="outlined"
                                            value={stateForm.entU}
                                            onChange={handleChange('entU')}
                                            disabled={identityUser.role === "user"}
                                        >
                                            {renderOPtionEnt()}
                                        </Select> */}
                    <Autocomplete
                      value={stateForm.entU}
                      onChange={(event, newValue) => {
                        // console.log(newValue)
                        // console.log(newValue.inputValue)
                        if (newValue && newValue.inputValue) {
                          handleChangeEnt(newValue.inputValue);
                          return;
                        }
                        handleChangeEnt(newValue);
                      }}
                      id="entU"
                      size="small"
                      className={classes.textField}
                      options={entreprise.listEnt.map(
                        (option) => option.nomEntreprise
                      )}
                      disabled={identityUser.role === "user"}
                      // getOptionLabel={option => option.nomEntreprise}
                      // onChange={handleChange('entrepriseSite')}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("UserTab.TableCell.entU") + "*"}
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="outlined" className={classes.divForm}>
                    {/* <InputLabel id="labelsiteU" className={classes.labelSelect}>{t('UserTab.TableCell.siteU')}</InputLabel>
                                        <Select
                                            id="siteU"
                                            multiple
                                            labelId="labelsiteU"
                                            margin="dense"
                                            className={classes.selectBox}
                                            variant="outlined"
                                            value={stateForm.siteU ? stateForm.siteU : ""}
                                            defaultValue={stateForm.siteU}
                                            onChange={handleChange('siteU')}
                                            disabled={stateForm.entU !== "" && identityUser.role !== "user" ? false : true}
                                        >
                                            {renderOPtionSite()}
                                        </Select> */}
                    <Autocomplete
                      value={stateForm.siteU}
                      multiple
                      limitTags={1}
                      onChange={(event, newValue) => {
                        // console.log(newValue)
                        // console.log(newValue.inputValue)
                        if (newValue && newValue.inputValue) {
                          handleChangeSite(newValue.inputValue);
                          return;
                        }
                        handleChangeSite(newValue);
                      }}
                      id="siteU"
                      className={classes.selectBox}
                      options={site}
                      disableCloseOnSelect
                      disabled={
                        stateForm.entU !== "" && identityUser.role !== "user"
                          ? false
                          : true
                      }
                      getOptionLabel={(option) => option.nomSite}
                      // onChange={handleChange('entrepriseSite')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="sites*"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.nomSite}
                        </React.Fragment>
                      )}
                    />
                  </FormControl>
                </FormGroup>
              </FormGroup>
            </FormGroup>
            <Divider />
            <FormGroup>
              <h4>Type User</h4>
              <FormControl variant="outlined" className={classes.formGrp2}>
                <InputLabel
                  id="labelTypeUser"
                  className={classes.labelSelect}
                  disabled={identityUser.role === "user"}
                >
                  {t("UserTab.TableCell.typeU") + "*"}
                </InputLabel>
                {renderTypeUser()}

                {stateForm.typeU === "partenaire" ? (
                  <FormControl variant="outlined" className={classes.divForm}>
                    <Autocomplete
                      id="grouped-demo"
                      multiple
                      limitTags={2}
                      value={stateForm.partname}
                      disableCloseOnSelect
                      options={entreprise.listEnt}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        // console.log(newValue.inputValue)
                        if (newValue && newValue.inputValue) {
                          handleChangeListPart(newValue.inputValue);
                          return;
                        }
                        handleChangeListPart(newValue);
                      }}
                      getOptionLabel={(option) => option.nomEntreprise}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.selectBox}
                          label="sites partenaire"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.nomEntreprise}
                        </React.Fragment>
                      )}
                    />
                  </FormControl>
                ) : (
                  <span></span>
                )}
              </FormControl>
            </FormGroup>

            <Divider />
            <FormGroup component="fieldset" className={classes.formGrp2}>
              <h4>Applications</h4>
              <FormControl variant="outlined" className={classes.divForm}>
                {/* <Select
                                multiple
                                    id="appU"
                                    margin="dense"
                                    className={classes.selectBox}
                                    variant="outlined"
                                    value={stateForm.appU}
                                    defaultValue={stateForm.appU}
                                    onChange={handleChange('appU')}
                                // disabled={stateForm.typeU === "device" ? false : true}
                                >
                                    {renderOptionApps()}
                                </Select> */}

                <Autocomplete
                  value={stateForm.appU}
                  multiple
                  limitTags={3}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    // console.log(newValue.inputValue)
                    if (newValue && newValue.inputValue) {
                      handleChangeAppArray(newValue.inputValue);
                      return;
                    }
                    handleChangeAppArray(newValue);
                  }}
                  id="appU"
                  className={classes.selectBox}
                  margin="dense"
                  options={apps}
                  disableCloseOnSelect
                  // disabled={stateForm.entU !== "" && identityUser.role !== "user" ? false : true}
                  getOptionLabel={(option) => option.appName}
                  // onChange={handleChange('entrepriseSite')}
                  renderInput={(params) => (
                    <TextField {...params} label="apps*" variant="outlined" />
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.appName}
                    </React.Fragment>
                  )}
                />
              </FormControl>
            </FormGroup>
            <Divider />
            <FormGroup component="fieldset" className={classes.formGrp2}>
              <h4>Préférences</h4>
              <FormControl variant="outlined" className={classes.divForm}>
                <InputLabel id="labelprefLang" className={classes.labelSelect}>
                  {t("UserTab.TableCell.prefLang")}
                </InputLabel>
                <Select
                  id="prefLang"
                  labelId="labelprefLang"
                  margin="dense"
                  className={classes.selectBox}
                  variant="outlined"
                  value={stateForm.prefLang}
                  defaultValue={stateForm.prefLang}
                  onChange={handleChange("prefLang")}
                  // disabled={stateForm.entU !== "" ? false : true}
                >
                  <MenuItem aria-label="None" value="">
                    {" "}
                    <em>None</em>{" "}
                  </MenuItem>
                  <MenuItem value="FR">
                    {" "}
                    <em>{t("UserTab.Option.Francais")}</em>{" "}
                  </MenuItem>
                  <MenuItem value="EN">
                    {" "}
                    <em>{t("UserTab.Option.English")}</em>{" "}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.divForm}>
                <InputLabel id="labelprefUnit" className={classes.labelSelect}>
                  {t("UserTab.TableCell.prefUnit")}
                </InputLabel>
                <Select
                  id="prefUnitMes"
                  labelId="labelprefUnitMes"
                  margin="dense"
                  className={classes.selectBox}
                  variant="outlined"
                  value={stateForm.prefUnitMes}
                  defaultValue={stateForm.prefUnitMes}
                  onChange={handleChange("prefUnitMes")}
                  // disabled={stateForm.entU !== "" ? false : true}
                >
                  <MenuItem aria-label="None" value="">
                    {" "}
                    <em>None</em>{" "}
                  </MenuItem>
                  <MenuItem value="metric">
                    {" "}
                    <em>{t("UserTab.Option.Metric")}</em>{" "}
                  </MenuItem>
                  <MenuItem value="imperial">
                    {" "}
                    <em>{t("UserTab.Option.Imperial")}</em>{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            </FormGroup>

            <Collapse in={openMsgErrEmpty}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrEmpty(false);
                }}
              >
                {t("error.errorMsgEmpty")}
              </Alert>
            </Collapse>
            <Collapse in={openMsgErrMailExist}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrMailExist(false);
                }}
              >
                {t("error.errorMsgMailExist")}
              </Alert>
            </Collapse>
            <Collapse in={openErrEmail}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenErrEmail(false);
                }}
              >
                {t("error.errorMsgNotMailFormat")}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("UserTab.annuler")}
            </Button>
            <Button onClick={handleValidate} color="primary">
              {t("UserTab.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSupp}
          onClose={handleCloseSupp}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{t("Buttons.suppr")}</DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <DialogContentText>{t("UserTab.confirmSupp")}</DialogContentText>
            <DialogActions>
              <Button onClick={handleCloseSupp} color="primary">
                {t("Buttons.non")}
              </Button>
              <Button onClick={clickSupp} color="primary">
                {t("Buttons.oui")}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

// UserTable.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default UserTable;
