import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import imgModif from "../static/images/modifier.png";
import imgSupp from "../static/images/supprimer.png";
import imgDetail from "../static/images/details.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import NavBar from "../Components/NavBar";
import YtComponent from "../Components/YtComponent";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
import imgbtnSettings from "../static/images/settings.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";

import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";

import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import cookie from "js-cookie";

import axios from "../plugins/axios";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "inline-flex",
    width: "100%",
  },
  rootwNavOpen: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  // navOpen: {
  //   width: "260px"
  // },
  // navClose: {
  //   width:"70px"
  // },
  table: {
    // minWidth: 1300,
    overflow: "auto",
    width: "100%",
  },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "90%",
    margin: "10px",
  },
  divForm: {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    // margin: theme.spacing(3)
  },
  dialogDetail: {
    width: "550px",
  },
  columnEdit: {
    width: "50%",
  },
  tableCell: {
    textAlign: "center",
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  btnAdd: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  formGrp: {
    width: "100%",
  },
  rightSideEntete: {
    width: "35%",
    minWidth: "600px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  btnsReinit: {
    marginTop: "15px",
  },
  volumeTotalDiv: {
    width: "319px",
    height: "108px",
    margin: "40px",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnOpenNavDroite: {
    // margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
  filtrageRow: {
    backgroundColor: "#F2F2F2",
  },
  txtfield: {
    backgroundColor: "#FFF",
    height: "40px",
    width: "100%",
  },
  txtfieldDate: {
    position: "absolute",
    zIndex: "1",
    marginTop: "-8px",
    marginLeft: "5px",
    // color: "#3f51b5"
  },
  btnOneReinit: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
  btnTwoReinit: {
    backgroundColor: "#25C2F2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
  // paper: {
  //     padding: theme.spacing(2),
  //     margin: 'auto',
  //     maxWidth: 200,
  // },
  vignette: {
    width: "300px",
    display: "inline-block",
    margin: "30px",
  },
  vignetteSelect: {
    width: "300px",
    display: "inline-block",
    margin: "30px",
    opacity: 0.5,
    position: "relative",
  },
  buttondel: {
    position: "absolute",
    left: "5px",
    // backgroundColor: "#ff4747",
    color: "#ff0000",
  },
  titlevignette: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  ytVideos: {
    display: "inline-flex",
    width: "85%",
    // padding: 10,
    // columns: "2 50px",
    margin: "auto",
  },
  ytVideoLeft: {
    width: "50%",
    // backgroundColor: "red",
    margin: 10,
  },
  ytVideoRight: {
    width: "50%",
    // backgroundColor: "blue",
    margin: 10,
  },
  // vid: { margin: "10px", width: "700px", height: "400px" }
}));

function DashboardComp() {
  // url piink3d => https://youtube.com/playlist?list=PLKpmJEqVN5ZyklLxG6CcuezGm8KjWoaNx
  // url piinkscan => https://youtube.com/playlist?list=PLKpmJEqVN5ZybOc4VcBrUsfJoNeaknUl4

  var tabYT = [
    "bJfn7S6JgbU",
    "1ZRMOhC0y-c",
    "bJfn7S6JgbU",
    "1ZRMOhC0y-c",
    "1ZRMOhC0y-c",
  ];

  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://192.168.1.13:3004"
    axios_customUrl = "https://auth.piink-teknology.com";
  }
  const { t, i18n } = useTranslation();

  const [apps, setApps] = React.useState([]);
  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
  });
  const [videosYoutube, setVideosYoutube] = React.useState([]);
  const [tabVids, setTabVids] = React.useState([]);
  const [urlVids, setUrlVids] = React.useState("");
  const [orderVids, setOrderVids] = React.useState("");
  const classes = useStyles();
  const [newUrl, setNewUrl] = React.useState("");
  const [newOrder, setNewOrder] = React.useState("");

  const [checked, setChecked] = React.useState(true);
  const [rightBarState, setRightBarState] = React.useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);
  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");
  var loc = "";

  const [createOrUpdate, setCreateOrUpdate] = React.useState(false);
  const [openModify, setOpenModify] = React.useState(false);
  const [openModifyYtVids, setOpenModifyYtVids] = React.useState(false);
  const [appSelected, setAppSelected] = React.useState({
    _id: "",
    appName: "",
    logoName: "",
    urlApp: "",
    logo: "",
    createdAt: "",
    updatedAt: "",
  });

  //handle errors
  const [openMsgErrEmpty, setOpenMsgErrEmpty] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [videosYoutubeLeft, setVideosYoutubeLeft] = React.useState([]);
  const [videosYoutubeRight, setVideosYoutubeRight] = React.useState([]);

  function logout() {
    // console.log(inf)
    // var wiioauthTok = cookie.get("wiioauth")
    // console.log(token)
    // console.log(wiioauthTok)
    cookie.remove("token");
    cookie.remove("infosUser");

    //test logout wiioauth redirect don't wwork
    loc = axios_customUrl + "/logout?client_id=piinkHub&token=" + token;

    window.location = loc;
  }

  const callBackNav = (navData) => {
    setIsNavBarOpen(navData);
    console.log(navData);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }
    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }
  //definition du swicth
  // const IOSSwitch = withStyles(theme => ({
  //     root: {
  //         width: 42,
  //         height: 26,
  //         padding: 0,
  //         margin: theme.spacing(1),
  //     },
  //     switchBase: {
  //         padding: 1,
  //         '&$checked': {
  //             transform: 'translateX(16px)',
  //             color: theme.palette.common.white,
  //             '& + $track': {
  //                 backgroundColor: '#52d869',
  //                 opacity: 1,
  //                 border: 'none',
  //             },
  //         },
  //         '&$focusVisible $thumb': {
  //             color: '#52d869',
  //             border: '6px solid #fff',
  //         },
  //     },
  //     thumb: {
  //         width: 24,
  //         height: 24,
  //     },
  //     track: {
  //         borderRadius: 26 / 2,
  //         border: `1px solid ${theme.palette.grey[400]}`,
  //         backgroundColor: "#eb4034",
  //         opacity: 1,
  //         transition: theme.transitions.create(['background-color', 'border']),
  //     },
  //     checked: {},
  //     focusVisible: {},
  // }))(({ classes, ...props }) => {
  //     return (
  //         <Switch
  //             focusVisibleClassName={classes.focusVisible}
  //             disableRipple
  //             classes={{
  //                 root: classes.root,
  //                 switchBase: classes.switchBase,
  //                 thumb: classes.thumb,
  //                 track: classes.track,
  //                 checked: classes.checked,
  //             }}
  //             {...props}
  //         />
  //     );
  // });

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  // const handleChangeCollapse = () => {
  //     setChecked(prev => !prev);
  // };

  const handleChange = (name) => (event) => {
    console.log("handleChange ");

    console.log(appSelected);
    setAppSelected({ ...appSelected, [name]: event.target.value });
  };

  useEffect(() => {
    async function fetchData() {
      console.log("fetchData");
      var response = {};
      // setIsDataCharging(true)
      var usersInfo = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );

      console.log(usersInfo.data);
      console.log(infsplit[1]);

      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
      });

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }

      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/app"
        );
      } else {
        var listIdApp = [];
        usersInfo.data.appArray.forEach((elt) => {
          listIdApp.push(elt._id);
        });
        console.log(listIdApp);
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/app",
          {
            params: {
              appArray: listIdApp,
            },
          }
        );
      }

      for (var i = 0; i < response.data.length; i++) {
        console.log(response.data[i].logoName);
        //get logo for each app
        var logo = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            `/app/logo?logoName=${response.data[i].logoName}`,
          { responseType: "arraybuffer" }
        );

        if (logo !== false) {
          var base64 = btoa(
            new Uint8Array(logo.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          response.data[i].logo = "data:;base64," + base64;
        }
      }

      // getYtVideos()
      console.log("getVideoYt");
      var videos = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/videoyt"
      );
      var videosLeft = [];
      var videoRight = [];
      for (var i = 0; i < videos.data.length; i++) {
        if (videos.data[i].order === 0) {
          videosLeft.push(videos.data[i]);
        } else {
          videoRight.push(videos.data[i]);
        }
      }

      console.log(videosLeft);
      console.log(videoRight);
      setVideosYoutubeLeft(videosLeft);
      setVideosYoutubeRight(videoRight);

      console.log(response.data);
      setApps(response.data);
      // setIsDataCharging(false)
    }

    fetchData();
  }, []);

  function goToApp(row) {
    // console.log(row.urlApp)
    if (createOrUpdate === false) {
      if (row.urlApp !== undefined && row.urlApp !== "") {
        var loc = row.urlApp;
        window.location = loc;
      }
    } else {
      console.log("call update app");
      if (row._id === "new") {
        row = {
          appName: "",
          logoName: "",
          urlApp: "",
        };
      }
      var app = {
        appName: row.appName,
        logoName: row.logoName,
        urlApp: row.urlApp,
      };
      console.log("row =>");
      console.log(row);
      setAppSelected(row);
      setOpenModify(true);
    }
  }

  function handleCloseModify() {
    setOpenModify(false);
    setAppSelected({
      _id: "",
      appName: "",
      logoName: "",
      urlApp: "",
      createdAt: "",
      updatedAt: "",
    });
  }

  async function handleCloseModifyYtVids() {
    setOpenModifyYtVids(false);
    setUrlVids("");
    setOrderVids("");
    var videos = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/videoyt"
    );
    setVideosYoutube(videos.data);
  }

  const handleChangeUrlVids = (event) => {
    setUrlVids(event.target.value);
  };

  const handleChangeOrderVids = (event) => {
    setOrderVids(event.target.value);
  };

  function clickCreateOrUpdate() {
    //update status
    setCreateOrUpdate(!createOrUpdate);

    //show button add application
    if (!createOrUpdate) {
      // console.log("ajouter le btn add")
      // console.log(i18n.language)
      if (i18n.language === "fr") {
        apps.push({
          _id: "new",
          appName: "Ajouter une application",
          logoName: "details.png",
          logo: "details.png",
        });
      } else {
        apps.push({
          _id: "new",
          appName: "Add an application",
          logoName: "details.png",
          logo: "details.png",
        });
      }

      setApps(apps);
    } else {
      apps.splice(apps.length - 1, 1);
      // console.log("supprimer le btn add")
    }
  }

  function uploadLogo() {
    handleChange("logoName");
    setAppSelected({ ...appSelected, ["logoName"]: "" });
  }

  async function handleValidateYtVids() {
    console.log(orderVids);
    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/videoyt",
      {
        url: urlVids,
        order: orderVids,
      }
    );
    var vids = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/videoyt/getFullUrl"
    );
    setTabVids(vids.data);
    setUrlVids("");
    setOrderVids("");
  }

  const handleUpdateYtVids = async (video) => {
    // console.log("handleUpdateYtVids")
    // console.log(video)
    //#####TODO gestion pour que aucun champ de nsoit vide
    var urlToAdd;
    var orderToAdd;
    if (newUrl === "") {
      urlToAdd = video.url;
    } else {
      urlToAdd = newUrl;
    }

    // console.log(newOrder == 1)
    if (newOrder == 0 || newOrder == 1) {
      console.log("orderToAdd = newOrder");
      orderToAdd = newOrder;
    } else {
      console.log("orderToAdd = video.order");
      orderToAdd = video.order;
    }

    // console.log("TO ADD =>")
    // console.log(urlToAdd)
    // console.log(orderToAdd)
    await axios.defaultAxios.put(
      axios.defaultAxios.defaults.baseURL + "/videoyt",
      {
        _id: video._id,
        url: urlToAdd,
        order: orderToAdd,
      }
    );

    setNewUrl("");
    setNewOrder("");
    var vids = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/videoyt/getFullUrl"
    );
    setTabVids(vids.data);
  };

  const handleClickDelVids = async (video) => {
    console.log("del => " + video._id + ", url =>" + video.url);
    await axios.defaultAxios.delete(
      axios.defaultAxios.defaults.baseURL + "/videoyt",
      {
        data: {
          _id: video._id,
        },
      }
    );
    var vids = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/videoyt/getFullUrl"
    );
    setTabVids(vids.data);
  };

  async function handleValidate() {
    // console.log("appSelected =>")
    // console.log(appSelected)
    // console.log(appSelected.appName)
    // console.log(appSelected.logoName)
    // console.log(appSelected.urlApp)

    //gestion des messages d'erreur
    if (
      appSelected.appName !== "" &&
      appSelected.logoName !== "" &&
      appSelected.urlApp !== "" &&
      appSelected.appName !== undefined &&
      appSelected.logoName !== undefined &&
      appSelected.urlApp !== undefined
    ) {
      // console.log(appSelected.urlApp)
      if (appSelected._id !== "" && appSelected._id !== undefined) {
        // console.log(appSelected._id)
        await axios.defaultAxios.put(
          axios.defaultAxios.defaults.baseURL + "/app",
          {
            _id: appSelected._id,
            appName: appSelected.appName,
            logoName: appSelected.logoName,
            urlApp: appSelected.urlApp,
          }
        );
        handleCloseModify();
        window.location.reload(false);
      } else {
        // console.log("no param _id")
        await axios.defaultAxios.post(
          axios.defaultAxios.defaults.baseURL + "/app",
          {
            appName: appSelected.appName,
            logoName: appSelected.logoName,
            urlApp: appSelected.urlApp,
          }
        );
        handleCloseModify();
        window.location.reload(false);
      }

      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append("piinklogo", selectedFile, selectedFile.name);

      // Details of the uploaded file
      // console.log(selectedFile);

      // Request made to the backend api
      // Send formData object
      axios.defaultAxios.post(
        axios.defaultAxios.defaults.baseURL + "/app/logo",
        formData
      );
    } else {
      setOpenMsgErrEmpty(true);
    }
  }

  const onFileChange = (event) => {
    // Update the state
    // console.log(event.target.files[0].name)
    appSelected.logoName = event.target.files[0].name;
    setSelectedFile(event.target.files[0]);
    // console.log(appSelected.logoName)
    console.log(appSelected);
  };

  // // On file select (from the pop up)
  // onFileChange = event => {

  //     // Update the state
  //     this.setState({ selectedFile: event.target.files[0] });

  // };

  // // On file upload (click the upload button)
  // onFileUpload = () => {

  //     // Create an object of formData
  //     const formData = new FormData();

  //     // Update the formData object
  //     formData.append(
  //         "myFile",
  //         this.state.selectedFile,
  //         this.state.selectedFile.name
  //     );

  //     // Details of the uploaded file
  //     console.log(this.state.selectedFile);

  //     // Request made to the backend api
  //     // Send formData object
  //     axios.defaultAxios.post("api/uploadfile", formData);
  // };

  async function delApp() {
    // console.log("supp")
    await axios.defaultAxios.delete(
      axios.defaultAxios.defaults.baseURL + "/app",
      {
        params: {
          _id: appSelected._id,
        },
      }
    );
    handleCloseModify();
    window.location.reload(false);
  }

  async function openEditVideosYt() {
    var vids = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/videoyt/getFullUrl"
    );
    console.log(vids.data);
    setTabVids(vids.data);
    setOpenModifyYtVids(true);
  }

  const changeNewUrl = (event) => {
    setNewUrl(event.target.value);
  };

  const changeNewOrder = (event) => {
    console.log(event.target.value);
    setNewOrder(event.target.value);
  };

  // async function getYtVideos(){
  //     var videos = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/ytvideo')
  //     setVideosYoutube(videos.data)
  // }

  return (
    <div className={classes.body}>
      <NavBar
        pageCallBack={callBackNav}
        dataFromParent={"dashboard"}
        dataIsUser={identityUser.role}
        className={{ width: 60 }}
      />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <div className={classes.entete}>
          <div className={classes.volumeTotalDiv}>
            <h1>{t("Dashboard.title")}</h1>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                {identityUser.ent}
              </span>
            </div>
            <Divider />
            <div className={classes.btnsReinit}>
              {identityUser.role === "adminPiink" ? (
                <div>
                  {createOrUpdate === false ? (
                    <Button
                      className={classes.btnOneReinit}
                      onClick={() => clickCreateOrUpdate()}
                      className={classes.btnAdd}
                    >
                      {t("Buttons.modify")}
                    </Button>
                  ) : (
                    <Button
                      className={classes.btnOneReinit}
                      onClick={() => clickCreateOrUpdate()}
                      className={classes.btnAdd}
                    >
                      {t("Buttons.cancel")}
                    </Button>
                  )}
                  <Button
                    classeName={classes.btnTwoReinit}
                    onClick={() => openEditVideosYt()}
                    className={classes.btnAdd}
                  >
                    {" "}
                    {t("Buttons.modifyYtVideos")}{" "}
                  </Button>
                </div>
              ) : (
                <span></span>
              )}

              {/* <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('dashboard.Button.resetFilter')}</Button> */}
              {/* <Button className={classes.btnOneReinit} onClick={() => resetVolChecked()}>{t('ScanTable.Button.resetVol')}</Button>
                            <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('ScanTable.Button.resetFilter')}</Button> */}
            </div>
          </div>
        </div>
        <div>
          {apps.map((row) => (
            <ButtonBase onClick={(e) => goToApp(row)}>
              <Paper
                className={
                  createOrUpdate === true
                    ? classes.vignetteSelect
                    : classes.vignette
                }
              >
                {/* {
                                        createOrUpdate === true ? 
                                                <Button className={classes.buttondel} onClick={delApp}>Delete</Button>
                                        :
                                            <p></p>
                                    } */}

                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonBase className={classes.image}>
                      {/* {
                                                    row.logoName !== "" ?
                                                        <img className={classes.img} alt="complex" src={row.logoName} />
                                                    :
                                                    <span></span>
                                                } */}
                      {/* <div className={classes.img} style={{ backgroundImage: `url('/logosApp/${row.logoName}')`, width: "48px", height: "47px" }}></div> */}
                      {row._id === "new" ? (
                        <div
                          className={classes.img}
                          style={{
                            backgroundImage: `url('/logosApp/${row.logoName}')`,
                            width: "48px",
                            height: "47px",
                          }}
                        ></div>
                      ) : (
                        <span className={classes.photoPopup}>
                          <img
                            src={row.logo}
                            alt="application's icon"
                            className={classes.imgDisplay}
                          />
                        </span>
                      )}
                    </ButtonBase>
                  </Grid>
                  <Grid className={classes.titlevignette} item xs={6} container>
                    {row.appName}
                  </Grid>
                </Grid>
              </Paper>
            </ButtonBase>
          ))}
        </div>
        <div className={classes.ytVideos}>
          {/* {
                        videosYoutube.map(video => (
                        <YtComponent
                            videoId={video}
                        />
                    ))
                } */}

          <div className={classes.ytVideoLeft}>
            {videosYoutubeLeft.map((video) => (
              <YtComponent videoId={video.url} />
            ))}
          </div>
          <div className={classes.ytVideoRight}>
            {videosYoutubeRight.map((video) => (
              <YtComponent videoId={video.url} />
            ))}
          </div>
        </div>
        {/* <div className={classes.ytVideos}>
                    <YtComponent
                        videoId="bJfn7S6JgbU"
                    />
                </div> */}
        {/* <div className={classes.ytVideos}>
                    <YouTube
                        videoId='bJfn7S6JgbU'
                        className={classes.video}
                    // videoId="PLKpmJEqVN5ZyklLxG6CcuezGm8KjWoaNx"
                    />
                    <YouTube
                        videoId='1ZRMOhC0y-c'
                        className={classes.video}
                    // videoId="PLKpmJEqVN5ZybOc4VcBrUsfJoNeaknUl4"
                    />
                </div>
                <div className={classes.ytVideos}>
                    <YouTube
                        videoId='bJfn7S6JgbU'
                        className={classes.video}
                    // videoId="PLKpmJEqVN5ZyklLxG6CcuezGm8KjWoaNx"
                    />
                    <YouTube
                        videoId='1ZRMOhC0y-c'
                        className={classes.video}
                    // videoId="PLKpmJEqVN5ZybOc4VcBrUsfJoNeaknUl4"
                    />
                </div> */}
        <Dialog
          open={openModifyYtVids}
          onClose={handleCloseModifyYtVids}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <FormControl component="fieldset" className={classes.formGrp}>
              <FormGroup aria-label="position" row>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="urlVids"
                    label={t("Dashboard.urlVids") + "*"}
                    className={classes.textField}
                    variant="outlined"
                    value={urlVids}
                    onChange={handleChangeUrlVids}
                  />
                  <TextField
                    margin="dense"
                    type="number"
                    id="orderVids"
                    label={t("Dashboard.orderVids") + "*"}
                    className={classes.textField}
                    variant="outlined"
                    value={orderVids}
                    onChange={handleChangeOrderVids}
                  />
                  <Button type="button" onClick={(e) => handleValidateYtVids()}>
                    <img src={imgDetail} alt="imgdetail" />
                  </Button>
                </div>
              </FormGroup>
            </FormControl>
            <div height="100px">
              {tabVids.map((vid) => (
                <Table>
                  <TableRow>
                    <TableCell>
                      <TextField
                        defaultValue={vid.url}
                        onChange={changeNewUrl}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        defaultValue={vid.order}
                        onChange={changeNewOrder}
                        type="number"
                      />
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Button
                        type="button"
                        onClick={(e) => handleUpdateYtVids(vid)}
                      >
                        <img src={imgModif} alt="imgmodif" />
                      </Button>
                    </TableCell>

                    <TableCell align="right" className={classes.tableCell}>
                      <Button
                        type="button"
                        onClick={(e) => handleClickDelVids(vid)}
                      >
                        <img src={imgSupp} alt="imgsupp" />
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModifyYtVids} color="primary">
              {t("Buttons.close")}
            </Button>
            {/* <Button onClick={handleValidateYtVids} color="primary">
                            {t('Buttons.valid')}
                        </Button> */}
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModify}
          onClose={handleCloseModify}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <FormControl component="fieldset" className={classes.formGrp}>
              <FormGroup aria-label="position" row>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="appName"
                    label={t("Dashboard.appName") + "*"}
                    className={classes.textField}
                    variant="outlined"
                    value={appSelected.appName}
                    onChange={handleChange("appName")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="urlApp"
                    label={t("Dashboard.urlApp") + "*"}
                    className={classes.textField}
                    variant="outlined"
                    value={appSelected.urlApp}
                    onChange={handleChange("urlApp")}
                  />
                </div>
                <Divider />
                {appSelected.logoName === "" || appSelected.logoName == null ? (
                  <div className={classes.divForm}>
                    <span>{t("Dashboard.logoName") + "*"}</span>
                    <br />
                    <input type="file" onChange={onFileChange} />
                  </div>
                ) : (
                  <div className={classes.divForm}>
                    {/* <input type="file" onChange={this.onFileChange} />
                                    <button onClick={this.onFileUpload}>
                                        Upload!
                                    </button> */}
                    <TextField
                      margin="dense"
                      id="logoName"
                      label={t("Dashboard.logoName") + "*"}
                      className={classes.textField}
                      variant="outlined"
                      value={appSelected.logoName}
                      onChange={handleChange("logoName")}
                    />
                    <Button onClick={uploadLogo} color="primary">
                      {t("Dashboard.uploadOther")}
                    </Button>
                  </div>
                )}
              </FormGroup>
            </FormControl>

            <Collapse in={openMsgErrEmpty}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrEmpty(false);
                }}
              >
                {t("error.errorMsgEmpty")}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.buttondel}
              onClick={delApp}
              color="default"
            >
              Delete
            </Button>
            <Button onClick={handleCloseModify} color="primary">
              {t("Buttons.annuler")}
            </Button>
            <Button onClick={handleValidate} color="primary">
              {t("Buttons.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        {rightBarState ? (
          <Drawer
            className={classes.barreLatDroiteOpen}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper2,
            }}
            anchor="right"
          >
            {/* logout */}
            <Button
              className={classes.btnCloseNavDroite}
              onClick={(e) => toggleRightBarPanel(true)}
            >
              {" "}
              <img src={imgbtnClose} />{" "}
            </Button>
            v1.0.10
            <h2 className={classes.userNameBarLatOpen}>
              {identityUser.name} {identityUser.firstname}
            </h2>
            <span className={classes.typeUserBarLatOpen}>
              {identityUser.role}
            </span>
            <span className={classes.EntrepriseBarLatOpen}>
              {identityUser.ent}
            </span>
            <Divider className={classes.separatorRightBar} />
            <div className={classes.langDiv}>
              <IconButton onClick={() => changeLanguage("fr")}>
                <img src={drapeauFr} />
              </IconButton>
              <IconButton onClick={() => changeLanguage("en")}>
                <img src={drapeauEn} />
              </IconButton>
              {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
                        <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
            </div>
            <Divider className={classes.separatorRightBar} />
            {/* <span className={classes.labelRightBar}>filtres de recherche</span>
                    <FormControlLabel
                        className={classes.switchFilter}
                        control={<IOSSwitch
                            // className={classes.switchFilter}
                            checked={checked}
                            onChange={handleChangeCollapse} />}
                        label={checked ? "On" : "Off"}
                    /> */}
            <Button
              variant="contained"
              className={classes.btnLogout}
              endIcon={<img src={logoutIcon} />}
              onClick={() => logout()}
            >
              Logout
            </Button>
            {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
          </Drawer>
        ) : (
          <Drawer
            className={classes.barreLatDroiteClose}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <Button
              className={classes.btnOpenNavDroite}
              onClick={(e) => toggleRightBarPanel(false)}
            >
              {" "}
              <img src={imgbtnSettings} className={classes.imgbtnset} />{" "}
            </Button>
            <span className={classes.userSideTxt}></span>
            {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
          </Drawer>
        )}
      </div>
    </div>
  );
}

export default DashboardComp;
