import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {

    var axios_customUrl
    if (process.env.NODE_ENV === 'production') {
        axios_customUrl = "https://auth.piink-teknology.com"
    } else {
        axios_customUrl = "https://auth.piink-teknology.com"
    }

    return (
        <Route
            {...rest}
            render={props => 
                rest.loggedIn ? (
                //   true  ? (
                    <Component {...props} />
                ) : (
                        // <Redirect
                        //     to={{
                        //         pathname: "/",
                        //         state: { from: props.location }
                        //     }}
                        // />

                        <Route path='/' component = {() => {
                            window.location.href = axios_customUrl+'/oauth/authorize?client_id=piinkHub&response_type=code&redirect_uri=/dashboard';
                            return null;
                        }}/>
                )}
        />
    );
};

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn
    };
};

export default connect(mapStateToProps)(PrivateRoute);