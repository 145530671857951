import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NavBar from "../Components/NavBar";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
import imgbtnOpen from "../static/images/open.png";
import imgbtnSettings from "../static/images/settings.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";
import imgResetFilters from "../static/images/Composant5–1.png";

import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import imgModif from "../static/images/modifier.png";
import imgSupp from "../static/images/supprimer.png";

import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { Route, Redirect } from "react-router-dom";
import cookie from "js-cookie";

import axios from "../plugins/axios";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    display: "inline-flex",
    width: "100%",
  },
  rootwNavOpen: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  // navOpen: {
  //   width: "260px"
  // },
  // navClose: {
  //   width:"70px"
  // },
  table: {
    // minWidth: 1300,
    overflow: "auto",
    width: "100%",
  },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "90%",
    margin: "10px",
  },
  divForm: {
    width: "100%",
    marginBottom: "10px",
    // margin: theme.spacing(3)
  },
  dialogDetail: {
    width: "550px",
  },
  columnEdit: {
    width: "50%",
  },
  tableCell: {
    textAlign: "center",
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  btnAdd: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "20px",
    width: "250px",
    height: "50px",
  },
  formGrp: {
    width: "100%",
  },
  rightSideEntete: {
    width: "35%",
    minWidth: "600px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  btnsReinit: {
    marginTop: "15px",
    textAlign: "end",
  },
  volumeTotalDiv: {
    width: "319px",
    height: "108px",
    margin: "40px",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnOpenNavDroite: {
    // margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
  filtrageRow: {
    backgroundColor: "#F2F2F2",
  },
  txtfield: {
    backgroundColor: "#FFF",
    height: "40px",
    width: "100%",
  },
  txtfieldDate: {
    position: "absolute",
    zIndex: "1",
    marginTop: "-8px",
    marginLeft: "5px",
    // color: "#3f51b5"
  },
  btnOneReinit: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
  btnTwoReinit: {
    backgroundColor: "#25C2F2",
    color: "#FFF",
    margin: "10px",
    width: "250px",
    height: "50px",
  },
});

function EntrepriseTable() {
  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://192.168.1.13:3004"
    axios_customUrl = "https://auth.piink-teknology.com";
  }

  const [entreprises, setEntreprises] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openSupp, setOpenSupp] = React.useState(false);
  const [stateForm, setStateForm] = React.useState({
    _id: "",
    nomEntreprise: "",
    adrEntreprise: "",
    villeEntreprise: "",
    codePostalEntreprise: "",
    paysEntreprise: "",
    titleAddOrUpdate: t("Buttons.add"),
    addOrUpdate: false,
  });
  const [stateFormFilter, setStateFormFilter] = React.useState({
    nomEntreprise: "",
    adrEntreprise: "",
    villeEntreprise: "",
    codePostalEntreprise: "",
    paysEntreprise: "",
  });
  const [stateFilter, setStateFilter] = React.useState("");
  const [rowToSupp, setRowToSupp] = React.useState("");
  const [filterOn, setFilterOnOff] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pagination, setPagination] = React.useState({
    entriesLength: 0,
  });

  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
    parts: [],
  });

  const classes = useStyles();
  const [rightBarState, setRightBarState] = React.useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);
  const [checked, setChecked] = React.useState(true);
  const [firstCall, setFirstCall] = React.useState(true);
  const [isDataCharging, setIsDataCharging] = React.useState(false);

  const [isToggled, setToggled] = useState(true);
  const handleChangeLocked = () => setToggled(!isToggled);

  const [isToggledCascade, setToggledCascade] = useState(true);
  const handleChangeLockedCascadeSupp = () =>
    setToggledCascade(!isToggledCascade);

  // const monthFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
  // const monthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");

  //handle errors
  const [openMsgErrEmpty, setOpenMsgErrEmpty] = React.useState(false);

  var loc = "";
  function logout() {
    // console.log(inf)
    // var wiioauthTok = cookie.get("wiioauth")
    // console.log(token)
    // console.log(wiioauthTok)
    cookie.remove("token");
    cookie.remove("infosUser");

    //test logout wiioauth redirect don't wwork
    loc = axios_customUrl + "/logout?client_id=piinkHub&token=" + token;

    window.location = loc;
  }

  useEffect(() => {
    if (
      stateFormFilter.nomEntreprise ||
      stateFormFilter.adrEntreprise ||
      stateFormFilter.villeEntreprise ||
      stateFormFilter.codePostalEntreprise ||
      stateFormFilter.paysEntreprise
    ) {
      //si date et date2 alors test que date < date2
      // console.log(stateFormFilter.date)
      // console.log(new Date(stateFormFilter.date))
      // console.log("call filter")
      callFilter();
    } else {
      // console.log(" call simple")
      callSimple();
    }
  }, [stateFormFilter]);

  useEffect(() => {
    async function fetchData() {
      setIsDataCharging(true);
      var usersInfo = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );

      if (
        !usersInfo.data.sitePartenaire.includes(
          usersInfo.data.entrepriseUser._id
        )
      ) {
        usersInfo.data.sitePartenaire.push(usersInfo.data.entrepriseUser._id);
      }

      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
        parts: usersInfo.data.sitePartenaire,
      });

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }
      if (infsplit[1] === "adminPiink") {
        var response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company/count"
        );
        setPagination({ entriesLength: response3.data });
      } else if (infsplit[1] === "partenaire") {
        if (
          usersInfo.data.sitePartenaire !== undefined &&
          usersInfo.data.sitePartenaire !== null
        ) {
          var response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL +
              "/company/getCompaniesForPartenaire",
            {
              params: {
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );

          var response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/company/count",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );
          setPagination({ entriesLength: response3.data });
        }
      } else if (infsplit[1] === "user") {
        return <Redirect to="/dashboard" />;
      }

      var entreprises = response.data;

      setEntreprises(entreprises);

      setFirstCall(false);
      setIsDataCharging(false);
    }

    fetchData();
  }, []);

  const callBackNav = (navData) => {
    setIsNavBarOpen(navData);
    // console.log(navData)
  };

  const handleChangeCollapse = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeFilter = (name) => (event) => {
    setStateFormFilter({ ...stateFormFilter, [name]: event.target.value });
    // console.log(stateFormFilter)
    //wait change then -> callFilter()
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }
    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }
  //definition du swicth
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#eb4034",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const handleChange = (name) => (event) => {
    // console.log("change ")
    setStateForm({ ...stateForm, [name]: event.target.value });
  };

  async function handleChangePage(event, newPage) {
    setIsDataCharging(true);
    // console.log(newPage)
    setPage(newPage);

    var response = {};

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);
      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = newPage;
      paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

      if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/company/getEntrepriseFilter",
        {
          params: paramsFilter,
        }
      );
    } else {
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            params: {
              nbOfPage: newPage,
              nbDisplay: rowsPerPage + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        var response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/company/getCompaniesForPartenaire",
          {
            params: {
              nbOfPage: newPage,
              nbDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      }
    }

    // call measure table with updated params

    var companies = response.data;
    setEntreprises(companies);

    setIsDataCharging(false);
  }

  async function handleChangeRowsPerPage(event, newRowPerPage) {
    setIsDataCharging(true);
    // console.log(newRowPerPage.props.value)
    setRowsPerPage(newRowPerPage.props.value);
    setPage(0);

    var response = {};

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = 0;
      paramsFilter.nbMeasureDisplay = newRowPerPage.props.value + 1;

      if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/company/getEntrepriseFilter",
        {
          params: paramsFilter,
        }
      );
    } else {
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            params: {
              nbOfPage: 0,
              nbDisplay: newRowPerPage.props.value + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        var response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/company/getCompaniesForPartenaire",
          {
            params: {
              nbOfPage: 0,
              nbDisplay: newRowPerPage.props.value + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );
      }
    }

    var companies = response.data;
    setEntreprises(companies);

    setIsDataCharging(false);
    // call measure table with updated params
  }

  function clickAdd(row) {
    console.log(row);
    handleClickOpen();
    if (row === undefined) {
      stateForm._id = "";
      stateForm.nomEntreprise = "";
      stateForm.adrEntreprise = "";
      stateForm.villeEntreprise = "";
      stateForm.paysEntreprise = "";
      stateForm.codePostalEntreprise = "";
      stateForm.titleAddOrUpdate = t("Buttons.add");
      stateForm.addOrUpdate = false;
    } else {
      stateForm._id = row._id;
      stateForm.nomEntreprise = row.nomEntreprise;
      stateForm.adrEntreprise = row.adrEntreprise;
      stateForm.villeEntreprise = row.villeEntreprise;
      stateForm.codePostalEntreprise = row.codePostalEntreprise;
      stateForm.paysEntreprise = row.paysEntreprise;
      stateForm.titleAddOrUpdate = t("Buttons.update");
      stateForm.addOrUpdate = true;
    }
    // console.log(stateForm)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenSupp = (row) => {
    setRowToSupp(row);
    setOpenSupp(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSupp = () => {
    setRowToSupp("");
    setOpenSupp(false);
  };

  async function handleValidate() {
    console.log(stateForm);
    if (
      stateForm.nomEntreprise !== "" &&
      stateForm.nomEntreprise !== undefined
    ) {
      if (stateForm.addOrUpdate === true) {
        await axios.defaultAxios.put(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            _id: stateForm._id,
            nomEntreprise: stateForm.nomEntreprise,
            adrEntreprise: stateForm.adrEntreprise,
            villeEntreprise: stateForm.villeEntreprise,
            codePostalEntreprise: stateForm.codePostalEntreprise,
            paysEntreprise: stateForm.paysEntreprise,
          }
        );
      } else {
        var newComp = await axios.defaultAxios.post(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            nomEntreprise: stateForm.nomEntreprise,
            adrEntreprise: stateForm.adrEntreprise,
            villeEntreprise: stateForm.villeEntreprise,
            codePostalEntreprise: stateForm.codePostalEntreprise,
            paysEntreprise: stateForm.paysEntreprise,
          }
        );
        console.log(newComp.data.scan._id);
        if (isToggled) {
          //create site associated to the new company
          await axios.defaultAxios.post(
            axios.defaultAxios.defaults.baseURL + "/site",
            {
              id: stateForm.nomEntreprise + " - " + stateForm.nomEntreprise,
              nomSite: stateForm.nomEntreprise,
              entrepriseSite: newComp.data.scan._id,
              adresseSite: stateForm.adrEntreprise,
              paysSite: stateForm.paysEntreprise,
              villeSite: stateForm.villeEntreprise,
              codePostalSite: stateForm.codePostalEntreprise,
              locked: true,
            }
          );
        }
      }

      setToggled(true);

      if (infsplit[1] === "adminPiink") {
        var response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
        setEntreprises(response2.data);

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company/count"
        );
        setPagination({ entriesLength: response3.data });
      } else if (infsplit[1] === "partenaire") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/company/getCompaniesForPartenaire",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company/count",
          {
            params: {
              listPartenaire: identityUser.parts,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      }

      handleClose();
    } else {
      setOpenMsgErrEmpty(true);
    }
  }

  async function clickSupp() {
    //archive measure linked to this companie
    if (isToggledCascade) {
      var siteEnt = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/site/getSiteFromEnt",
        {
          params: {
            entId: rowToSupp.nomEntreprise,
          },
        }
      );

      var sitesId = [];
      siteEnt.data.forEach((elt) => {
        sitesId.push(elt._id);
      });
      // console.log(siteEnt.data)
      // console.log(sitesId)

      //disable the measures
      await axios.customAxios.put(
        axios.customAxios.defaults.baseURL + "/measure/disableAllFromSameEnt",
        {
          siteMeasure: sitesId,
          nomEntreprise: rowToSupp.nomEntreprise,
        }
      );

      //disable the scans
      await axios.customAxios2.put(
        axios.customAxios2.defaults.baseURL + "/scan/disableAllFromSameEnt",
        {
          siteMeasure: sitesId,
          nomEntreprise: rowToSupp.nomEntreprise,
        }
      );
    }

    // console.log(rowToSupp.nomEntreprise)
    await axios.defaultAxios.delete(
      axios.defaultAxios.defaults.baseURL + "/company",
      {
        data: {
          nomEntreprise: rowToSupp._id,
          deleteCascade: isToggledCascade,
        },
      }
    );
    var response2 = [];
    if (infsplit[1] === "adminPiink") {
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/company",
        {
          params: {
            nbOfPage: page,
            nbMeasureDisplay: rowsPerPage + 1,
            listPartenaire: identityUser.parts,
          },
        }
      );
    } else {
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL +
          "/company/getCompaniesForPartenaire",
        {
          params: {
            nbOfPage: page,
            nbMeasureDisplay: rowsPerPage + 1,
            listPartenaire: identityUser.parts,
          },
        }
      );
    }

    setEntreprises(response2.data);

    handleCloseSupp();
  }

  // function setDateString() {
  //     var dateToday = new Date()
  //     var day = dateToday.getDate()
  //     var month = dateToday.getUTCMonth()
  //     var year = dateToday.getFullYear()

  //     var dateOfTodayVar = ""
  //     if (i18n.language === "fr") {
  //         month = monthFr[month]
  //     } else if (i18n.language === "en") {
  //         month = monthEn[month]
  //     }
  //     dateOfTodayVar = day + " " + month + " " + year
  //     // console.log(dateOfTodayVar)
  //     return (dateOfTodayVar)

  // }

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  async function ResetFilters() {
    setStateFormFilter({
      nomEntreprise: "",
      adrEntreprise: "",
      villeEntreprise: "",
      codePostalEntreprise: "",
      paysEntreprise: "",
    });
    // callSimple()
  }

  async function callFilter() {
    setIsDataCharging(true);
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);
    setPage(0);
    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = 0;
    paramsFilter.nbMeasureDisplay = rowsPerPage + 1;

    if (infsplit[1] === "partenaire") {
      paramsFilter.listPartenaire = identityUser.parts;
    }

    var response = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/company/getEntrepriseFilter",
      {
        params: paramsFilter,
      }
    );
    console.log(response.data);

    setFilterOnOff(true);
    setEntreprises(response.data);

    var response3 = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/company/countFilter",
      {
        params: paramsFilter,
      }
    );
    setPagination({ entriesLength: response3.data });
    setIsDataCharging(false);
  }

  function pressEnter(ev) {
    if (ev.key === "Enter") {
      callFilter();
      ev.preventDefault();
    }
  }

  async function callSimple() {
    setIsDataCharging(true);
    if (firstCall === false) {
      setStateFilter("");
      setPage(0);
      setFilterOnOff(false);

      var response2 = [];
      if (infsplit[1] === "adminPiink") {
        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company/count"
        );

        setPagination({ entriesLength: response3.data });

        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/company/getCompaniesForPartenaire",
          {
            params: {
              nbOfPage: page,
              nbMeasureDisplay: rowsPerPage + 1,
              listPartenaire: identityUser.parts,
            },
          }
        );

        var response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/company/count",
          {
            params: {
              listPartenaire: identityUser.parts,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      }

      setEntreprises(response2.data);
    }
    setIsDataCharging(false);
  }

  function filterConditions(paramsFilter) {
    if (
      stateFormFilter.nomEntreprise !== "" &&
      stateFormFilter.nomEntreprise !== undefined
    ) {
      paramsFilter.nomEntreprise = stateFormFilter.nomEntreprise; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.adrEntreprise !== "" &&
      stateFormFilter.adrEntreprise !== undefined
    ) {
      paramsFilter.adrEntreprise = stateFormFilter.adrEntreprise; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.villeEntreprise !== "" &&
      stateFormFilter.villeEntreprise !== undefined
    ) {
      paramsFilter.villeEntreprise = stateFormFilter.villeEntreprise; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.codePostalEntreprise !== "" &&
      stateFormFilter.codePostalEntreprise !== undefined
    ) {
      paramsFilter.codePostalEntreprise = stateFormFilter.codePostalEntreprise; //{ $regex: /789$/ }
    }

    if (
      stateFormFilter.paysEntreprise !== "" &&
      stateFormFilter.paysEntreprise !== undefined
    ) {
      paramsFilter.paysEntreprise = stateFormFilter.paysEntreprise; //{ $regex: /789$/ }
    }

    console.log(paramsFilter);

    return paramsFilter;
  }

  async function selectRow(event, rowInfo) {
    console.log("click entreprise");

    var loc =
      "http://" +
      window.location.host +
      "/sites?filterSite=" +
      rowInfo.nomEntreprise;
    // console.log(loc)
    // console.log(window.location.host)
    window.location = loc;
  }

  if (infsplit[1] === "user") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={classes.body}>
      {/* <button onClick={() => changeLanguage('fr')}>fr</button>
            <button onClick={() => changeLanguage('en')}>en</button>
            <div>
                <Button onClick={() => clickAdd()} >{t('Buttons.add')}</Button>
            </div> */}
      <NavBar pageCallBack={callBackNav} dataFromParent={"entreprisePage"} />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <div className={classes.entete}>
          <div className={classes.volumeTotalDiv}>
            <h1>{t("EntrepriseTab.title")}</h1>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                {identityUser.ent}
              </span>
            </div>
            <Divider />
            <div className={classes.btnsReinit}>
              <Button
                className={classes.btnOneReinit}
                onClick={() => clickAdd()}
                className={classes.btnAdd}
              >
                {t("Buttons.add")}
              </Button>
              {/* <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('Buttons.resetFilter')}</Button> */}
              {/* <Button className={classes.btnOneReinit} onClick={() => resetVolChecked()}>{t('ScanTable.Button.resetVol')}</Button>
                            <Button className={classes.btnTwoReinit} onClick={() => ResetFilters()}>{t('ScanTable.Button.resetFilter')}</Button> */}
            </div>
          </div>
        </div>
        <Paper className={classes.tableau}>
          {/* <Button onClick={() => clickAdd()} >{t('Buttons.add')}</Button> */}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {t("EntrepriseTab.TableCell.nomEnt")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("EntrepriseTab.TableCell.adrEnt")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("EntrepriseTab.TableCell.VilleEnt")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("EntrepriseTab.TableCell.codePostalEntreprise")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("EntrepriseTab.TableCell.PaysEnt")}
                </TableCell>
              </TableRow>
              <TableRow className={classes.filtrageRow}>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="nomEntreprise"
                      variant="outlined"
                      value={stateFormFilter.nomEntreprise}
                      onChange={handleChangeFilter("nomEntreprise")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="adrEntreprise"
                      variant="outlined"
                      value={stateFormFilter.adrEntreprise}
                      onChange={handleChangeFilter("adrEntreprise")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="villeEntreprise"
                      variant="outlined"
                      value={stateFormFilter.villeEntreprise}
                      onChange={handleChangeFilter("villeEntreprise")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="codePostalEntreprise"
                      variant="outlined"
                      value={stateFormFilter.codePostalEntreprise}
                      onChange={handleChangeFilter("codePostalEntreprise")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="paysEntreprise"
                      variant="outlined"
                      value={stateFormFilter.paysEntreprise}
                      onChange={handleChangeFilter("paysEntreprise")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button onClick={() => ResetFilters()}>
                    <img src={imgResetFilters} alt="detail" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataCharging ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                // <div>
                //   <CircularProgress />
                // </div>
                entreprises.map((row) => (
                  <TableRow hover key={row._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.nomEntreprise}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.adrEntreprise}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.villeEntreprise}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.codePostalEntreprise}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.paysEntreprise}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Button type="button" onClick={(e) => clickAdd(row)}>
                        <img src={imgModif} />
                      </Button>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Button
                        type="button"
                        onClick={(e) => handleClickOpenSupp(row)}
                      >
                        <img src={imgSupp} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={pagination.entriesLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="entreprise par page :"
          />
        </Paper>

        {rightBarState ? (
          <Drawer
            className={classes.barreLatDroiteOpen}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper2,
            }}
            anchor="right"
          >
            {/* logout */}
            <Button
              className={classes.btnCloseNavDroite}
              onClick={(e) => toggleRightBarPanel(true)}
            >
              {" "}
              <img src={imgbtnClose} />{" "}
            </Button>
            v1.0.10
            <h2 className={classes.userNameBarLatOpen}>
              {identityUser.name} {identityUser.firstname}
            </h2>
            <span className={classes.typeUserBarLatOpen}>
              {identityUser.role}
            </span>
            <span className={classes.EntrepriseBarLatOpen}>
              {identityUser.ent}
            </span>
            <Divider className={classes.separatorRightBar} />
            <div className={classes.langDiv}>
              <IconButton onClick={() => changeLanguage("fr")}>
                <img src={drapeauFr} />
              </IconButton>
              <IconButton onClick={() => changeLanguage("en")}>
                <img src={drapeauEn} />
              </IconButton>
              {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
                        <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
            </div>
            <Divider className={classes.separatorRightBar} />
            <span className={classes.labelRightBar}>filtres de recherche</span>
            <FormControlLabel
              className={classes.switchFilter}
              control={
                <IOSSwitch
                  // className={classes.switchFilter}
                  checked={checked}
                  onChange={handleChangeCollapse}
                />
              }
              label={checked ? "On" : "Off"}
            />
            <Button
              variant="contained"
              className={classes.btnLogout}
              endIcon={<img src={logoutIcon} />}
              onClick={() => logout()}
            >
              Logout
            </Button>
            {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
          </Drawer>
        ) : (
          <Drawer
            className={classes.barreLatDroiteClose}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <Button
              className={classes.btnOpenNavDroite}
              onClick={(e) => toggleRightBarPanel(false)}
            >
              {" "}
              <img src={imgbtnSettings} className={classes.imgbtnset} />{" "}
            </Button>
            <span className={classes.userSideTxt}></span>
            {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
          </Drawer>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {stateForm.titleAddOrUpdate}
          </DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <FormControl component="fieldset" className={classes.formGrp}>
              <FormGroup aria-label="position" row>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="nomEntreprise"
                    label={t("EntrepriseTab.TableCell.nomEnt") + "*"}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.nomEntreprise}
                    onChange={handleChange("nomEntreprise")}
                    disabled={stateForm.addOrUpdate}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="adrEntreprise"
                    label={t("EntrepriseTab.TableCell.adrEnt")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.adrEntreprise}
                    onChange={handleChange("adrEntreprise")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="paysEntreprise"
                    label={t("EntrepriseTab.TableCell.PaysEnt")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.paysEntreprise}
                    onChange={handleChange("paysEntreprise")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="codePostalEntreprise"
                    label={t("EntrepriseTab.TableCell.codePostalEntreprise")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.codePostalEntreprise}
                    onChange={handleChange("codePostalEntreprise")}
                  />
                </div>
                <div className={classes.divForm}>
                  <TextField
                    margin="dense"
                    id="villeEntreprise"
                    label={t("EntrepriseTab.TableCell.VilleEnt")}
                    className={classes.textField}
                    variant="outlined"
                    defaultValue={stateForm.villeEntreprise}
                    onChange={handleChange("villeEntreprise")}
                  />
                </div>
                {stateForm.addOrUpdate === true ? (
                  <span></span>
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isToggled}
                        onClick={handleChangeLocked}
                        color="primary"
                      />
                    }
                    label={t("EntrepriseTab.locked")}
                    labelPlacement="start"
                  />
                )}
              </FormGroup>
            </FormControl>

            <Collapse in={openMsgErrEmpty}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrEmpty(false);
                }}
              >
                {t("error.errorMsgEmpty")}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("SiteTab.annuler")}
            </Button>
            <Button onClick={handleValidate} color="primary">
              {t("SiteTab.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSupp}
          onClose={handleCloseSupp}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{t("Buttons.suppr")}</DialogTitle>
          <DialogContent className={classes.dialogDetail}>
            <FormControlLabel
              control={
                <Switch
                  checked={isToggledCascade}
                  onClick={handleChangeLockedCascadeSupp}
                  color="primary"
                />
              }
              label={t("EntrepriseTab.multiSupp")}
              labelPlacement="start"
            />
            <DialogContentText>{t("SiteTab.confirmSupp")}</DialogContentText>
            <DialogActions>
              <Button onClick={handleCloseSupp} color="primary">
                {t("Buttons.non")}
              </Button>
              <Button onClick={clickSupp} color="primary">
                {t("Buttons.oui")}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

// EntrepriseTable.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default EntrepriseTable;
